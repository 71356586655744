import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Layout/Header/Header";
import Breadcrumb from "../Layout/Header/Breadcrumb";
import { v4 as uuidv4 } from 'uuid';
import {
  CUSTOMER_MANAGER,
  adminSuperAdminCustomerManger,
  all,
  customerManagerProjectManager,
  errorFetchData,
  initiated,
  roleHandler,
  selectClient,
  surveyDetails,
  unAuthCssFill,role
} from "../../constants/Roles";
import { roleUrl, tableLoaderHandler } from "../../utils/dateConverter";
import { toast } from "react-toastify";
import { apiRequest } from "../../utils/apiHelper";

const SurveyList = () => {
  const [surveyData, setSurvayData] = useState([]);
  const navigate = useNavigate();
  const [selectedCustomer, setSelectedCustomer] = useState("All");
  const [isSurveyListLoading, setSurveyListIsLoading] = useState(true);
  const tableHeadings = [
    "INITIATED DATE",
    "CLIENT NAME",
    "PROJECT NAME",
    "REPRESENTATIVE",
    "STATUS",
    "CSI",
  ];
  const breadcrumbItems = [
    { name: "Home", href: "/" },
    { name: "Survey Details", current: true },
  ];

  useEffect(() => {
    fetchSurveyData();
  }, []);

  const navigateToFilledCSS = (css) => {
    if (css.customerSatisfactionIndex) {
      navigate(`/${css.id}/feedback-form`);
    }
  };

  const navigateToFillCSS = (css) => {
    if(roleHandler(customerManagerProjectManager) && css.cssStatus === initiated){
      navigate(`/${css.id}/feedback-form`);
    }
    else if (roleHandler(adminSuperAdminCustomerManger)) {
      navigate(`/${css.id}/feedback-form`);
    }
    else{
      toast.error(unAuthCssFill);
    }
  };
  const fetchSurveyData = async () => {
    try {
      const surveyData=await apiRequest('GET', roleUrl[role], {}, {});
      setSurvayData(surveyData.data);
      setSurveyListIsLoading(Array(surveyData.data).length > 0 ? false : true);
    } catch (error) {
      toast.error(errorFetchData);
    }
  };
  const handleCustomerSelection = (event) => {
    setSelectedCustomer(event.target.value);
  };
  
  return (
    <div className="bg-gray-900 min-h-screen">
      <div>
        <Header />
      </div>
      <div className="h-screen overflow-y-auto bg-gray-900 mt-2 items-center justify-center">
        <div className="mx-auto  px-4 py-16 sm:px-6 sm:py-6  lg:px-8 custom-bg-color">
          <Breadcrumb items={breadcrumbItems} />
          <div className="w-full flex justify-between items-center"></div>
          <div className="flex flex-col sm:flex-row items-center justify-between mt-4">
            <h3 className="font-medium leading-6 text-white sm:mt-0">
              {surveyDetails}
            </h3>

            {role !== CUSTOMER_MANAGER && (
              <div>
                {surveyData && surveyData.length > 0 && (
                  <select
                    id="location"
                    name="location"
                    className="bg-gray-800 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-white ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleCustomerSelection}
                  >
                    {surveyData && surveyData.length > 0 ? (
                      [
                        all,
                        ...new Set(
                          surveyData.map(
                            (customer) => customer.clientName
                          )
                        ),
                      ].map((name, index) => (
                        <option key={uuidv4()}>{name}</option>
                      ))
                    ) : (
                      <option>{selectClient}</option>
                    )}
                  </select>
                )}
              </div>
            )}
          </div>
          <div className="overflow-x-auto mt-4">
            {surveyData.length === 0 ? (tableLoaderHandler(isSurveyListLoading)
            ) : (
              <div className="border border-solid border-gray-700 rounded-lg p-4 mb-4">
                <table className="min-w-full divide-y divide-gray-700">
                  <thead className="bg-gray-800">
                    <tr>
                      {tableHeadings.map((heading, index) => (
                        <th
                          key={uuidv4()}
                          className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                        >
                          {heading}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-gray-900 divide-y divide-gray-700">
                    {surveyData
                      .filter((customer) =>
                        selectedCustomer === "All"
                          ? customer.clientName !==
                            selectedCustomer
                          : customer.clientName ===
                            selectedCustomer
                      )
                      .map((css, index) => (
                        <tr key={uuidv4()}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                            {new Date(css.createdAt).toLocaleDateString()}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                            {css.clientName}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                            {css.projectName}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                            {css.customerRepresentativeName}
                          </td>
                          <td
                            className={`px-6 py-4 whitespace-nowrap text-sm text-gray-300 hover:text-blue-900 cursor-pointer`}
                            onClick={() => navigateToFillCSS(css)}
                          >
                           
                            
                            {css.cssStatus}
                          </td>
                          <td
                            className={`px-6 py-4 whitespace-nowrap text-sm text-gray-300 ${
                              css.customerSatisfactionIndex !== null
                                ? "hover:text-blue-900 cursor-pointer"
                                : ""
                            }`}
                            onClick={() => navigateToFilledCSS(css)}
                          >
                            {css.customerSatisfactionIndex !== null
                              ? parseFloat(
                                  css.customerSatisfactionIndex
                                ).toFixed(2) + "%"
                              : ""}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyList;
