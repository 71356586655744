import React, { useState, useEffect,forwardRef, useImperativeHandle } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/cjs/Page/AnnotationLayer.css';
import 'react-pdf/dist/cjs/Page/TextLayer.css';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import { errorDownload, errorFetchData, monthlyReportDownload } from '../../constants/Roles';
import { apiBlobGet } from '../../utils/apiHelper';

const PDFViewer = forwardRef(({ uploadedFileView }, ref) => {
  
  
  const [numPages, setNumPages] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    const fetchData = async () => {
      if (uploadedFileView) {
        try {
          const url = `/report/download/${uploadedFileView}`;
          const response = await apiBlobGet(url);

          const pdfBlobUrl = URL.createObjectURL(response.data);
          setPdfUrl(pdfBlobUrl);
        } catch (error) {
          toast.error(errorFetchData);
        }
      }
    };
    fetchData();
  }, [uploadedFileView]);

  useImperativeHandle(ref, () => ({
    pdfDownloadFunction: async () => {
      try {
        const response = await fetch(pdfUrl);
        if (!response.ok) {
          toast.error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        saveAs(blob, uploadedFileView);
        toast.success(monthlyReportDownload);
      } catch (error) {
        toast.error(errorDownload);
      }
    },
  }));

  return (
    <div className="overflow-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-200 max-h-80 xl:h-[50rem] 2xl:h-screen lg:max-h-[23.125rem] max-w-full bg-transparent">
      <div className="text-white mt-8">
        {pdfUrl && (
          <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={index + 1}
                pageNumber={index + 1}
                className={index === numPages - 1 ? '' : 'mb-4'}
              />
            ))}
          </Document>
        )}
      </div>
    </div>
  );
});

export default PDFViewer;
