import axios from 'axios';
import { BASE_URL } from '../apiConfig';
import LocalStorage from '../components/StorageUtil/LocalStorage';
import { toast } from 'react-toastify';
import { unExpectedError } from '../constants/Roles';

const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  async (config) => {
    const {password} = LocalStorage.getResponseData()
    if (password) {
      config.headers.Authorization = `Bearer ${password}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export const apiRequest = async (method, url, data = {}, params = {}, isMultipart = false, isBlob = false) => {
  try {
    const config = {
      method,
      url,
      ...(method === 'GET' ? { params } : { data }),
      ...(isMultipart ? { headers: { 'Content-Type': 'multipart/form-data' } } : {}),
      ...(isBlob ? { responseType: 'blob' } : {}),
    };
    return await apiClient(config);
  } catch (error) {
    url!=="/login"?toast.error(unExpectedError):console.error(error);
    throw error; 
  }
};

//  GET requests
export const apiGet = (url, params) => apiRequest('GET', url, {}, params);

// GET BLOB requests
export const apiBlobGet = (url) => apiRequest('GET', url, {}, {}, false, true);

// POST requests
export const apiPost = (url, data) => apiRequest('POST', url, data);

// multipart POST requests
export const apiMultiPartPost = (url, data) => apiRequest('POST', url, data, {}, true);

//multipart PUT requests
export const apiMultiPartPut = (url, data) => apiRequest('PUT', url, data, {}, true);

//PUT requests
export const apiPut = (url, data) => apiRequest('PUT', url, data);

//DELETE requests
export const apiDelete = (url) => apiRequest('DELETE', url);