import React from "react";
import CSSCard from "./CSSCard";
import { cardLoading } from "../../utils/dateConverter";

const CSSList = ({ cssForms,isCssLoading,selectedCustomer }) => {
  const submittedCSS = cssForms.sort((a,b)=>{
    return new Date(b.updatedAt) - new Date(a.updatedAt)
  }).filter((form)=> selectedCustomer === "All" ? form.clientName !== selectedCustomer : form.clientName === selectedCustomer)
  return (
    <>
    {
      submittedCSS.length > 0 ? (  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-7 mt-4">
      {submittedCSS.slice(0,4).map((cssForm) => (
          <CSSCard cssForm={cssForm} key={cssForm.id}/>
          
      ))}
    </div>) : ( cardLoading(isCssLoading))
    }
    </>
  );
}; 
export default CSSList;
