// Client.js
import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../components/Layout/Header/Header";
import Breadcrumb from "../components/Layout/Header/Breadcrumb";
import ClientList from "../components/Client/ClientList";
import ClientModel from "../components/Client/ClientModal";
import { MyContext } from "../context/MyContext";
import { IFOCUS_SUPER_ADMIN, addCient, clientDetails, errorFetchData, failedToFetch, successCreate, successUpdate ,role} from "../constants/Roles";
import { BackwardIcon } from "@heroicons/react/24/outline";
import { v4 as uuidv4 } from 'uuid';
import { apiGet, apiPost, apiPut } from "../utils/apiHelper";
const Client = () => {
  const clientTableHeadings = [
    "CLIENT NAME",
    "LOCATION",
    "POC EMAIL ADDRESS",
    "POC NAME",
    "POC CONTACT NUMBER",
    "ACCOUNT Manager",
    "REMARKS",
    "PROJECT COUNT",
    "CLIENT STATUS"
  ];
  const[viewClientTable,setViewClientTable]=useState(false);
  const location = useLocation();
  const currentURL = location.pathname;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isLoading, setisLoading] = useState(true);
  const { setNavClientData } = useContext(MyContext);
  const initialValues = {
    clientName: "",
    location: "",
    pocName: "",
    pocContactNumber: "",
    pocEmailAddress: "",
    accountManager: "",
    clientStatus: "",
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    try {
      const clients = await apiGet(`/clients`);
      if(clients.status===200){
        setClients(clients.data);
        setisLoading(false)
      }
      else{
        toast.error(failedToFetch);
        setisLoading(false)
      }
      
    } catch (error) {
      toast.error(errorFetchData);
    }
  };

  const handleModalOpen = (client) => {
    setIsModalOpen(true);
    setSelectedClient(client);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedClient(null);
  };

  const handleSaveClient = async (values) => {
    try {
      if (selectedClient) {
        await apiPut(
          `/clients/${selectedClient.clientId}`,
          values
        );
        toast.success(successUpdate);
      } else {
        await apiPost(`/clients`, values);
        toast.success(successCreate);
      }
      fetchClients();
      setIsModalOpen(false);
      setSelectedClient(null);
    } catch (error) {
      toast.error(error.data?.response?.message)
    }
  };

  const breadcrumbItems = [
    { name: "Home", href: "#" },
    { name: "Clients", current: true },
  ];
  const viewClientTableHandle=()=>{
    setViewClientTable(!viewClientTable)
  }

  return (
    <>
      <Header />
      <div className="container mx-auto px-4 py-8">
        {/* Breadcrumb */}
        <Breadcrumb items={breadcrumbItems} />
        {viewClientTable?(<div className="bg-gray-900 min-h-screen">
          <div className="h-screen overflow-y-auto bg-gray-900 mt-2 items-center justify-center">
            <div className="mx-auto  px-4 py-16 sm:px-6 sm:py-6  lg:px-8 custom-bg-color">
              <div className="w-full flex justify-between items-center"></div>
              <div className="flex flex-col sm:flex-row items-center justify-between mt-4">
                <h3 className="font-medium leading-6 text-white sm:mt-0">
                  {clientDetails}
                </h3>
                <BackwardIcon className=" w-8 h-8 text-white hover:text-blue-400" onClick={()=>{setViewClientTable(false)}}/>
              </div>
              <div className="overflow-x-auto mt-4">
                {clients.length !== 0 && (
                  <div className="border border-gray-700 rounded-lg p-4 mb-4">
                    <table className="min-w-full divide-y divide-gray-700">
                      <thead className="bg-gray-800">
                        <tr>
                          {clientTableHeadings.map((heading, index) => (
                            <th
                            key={uuidv4()}
                              className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                            >
                              {heading}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="bg-gray-900 divide-y divide-gray-700">
                        {clients
                          .map((css, index) => (
                            <tr key={css.clientId}>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                                {css.clientName}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                                {css.location}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                                {css.pocEmailAddress}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                                {css.pocName}
                              </td>
                              <td
                                className={`px-6 py-4 whitespace-nowrap text-sm text-gray-300 hover:text-blue-900 cursor-pointer`}
                              >
                                {css.pocContactNumber}
                              </td>
                              <td
                                className={`px-6 py-4 whitespace-nowrap text-sm text-gray-300 hover:text-blue-900 cursor-pointer`}
                              >
                                {css.accountManager}
                              </td>
                              <td
                                className={`px-6 py-4 whitespace-nowrap text-sm text-gray-300 hover:text-blue-900 cursor-pointer`}
                              >
                                {css.remarks}
                              </td>
                              <td
                                className={`px-6 py-4 whitespace-nowrap text-sm text-gray-300 hover:text-blue-900 cursor-pointer`}
                              >
                                {css.projectCount}
                              </td>
                              <td
                                className={`px-6 py-4 whitespace-nowrap text-sm text-gray-300 hover:text-blue-900 cursor-pointer`}
                              >
                                {css.clientStatus}
                              </td>
                            </tr> 
                          ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>):(
            <div>
        <div className="flex justify-end mb-10">
          {currentURL === "/manage-clients" && role === IFOCUS_SUPER_ADMIN ? (
            <button
              className="custom-btn-color text-white px-3 py-1 rounded-sm w-40 h-11"
              onClick={() => handleModalOpen(null)}
            >
              {addCient}
            </button>
          ) : null}
        </div>
        {/* Cards Section */}
        <ClientList
          clients={clients}
          handleEdit={handleModalOpen}
          setNavClientData={setNavClientData}
          isLoading={isLoading}
          viewClientTable={viewClientTableHandle}
        />

        {/* Add/Update Client Modal */}
        {isModalOpen && (
          <ClientModel
            onClose={handleModalClose}
            initialValues={selectedClient || initialValues}
            onSubmit={handleSaveClient}
            clients={clients}
          />
        )}
      </div>
        )}
        </div>

    </>
  );
};

export default Client;
