import React, { useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { adminSuperAdmin, roleHandler } from "../../constants/Roles";
import {  EnvelopeIcon, EyeIcon, PencilSquareIcon, UserIcon, ViewfinderCircleIcon } from "@heroicons/react/24/outline";
import { useClickOutside,isCurrentPath } from "../../utils/dateConverter";

const ProjectMangerCard = ({ user, handleEdit,viewTable }) => {
  const [projectOptions, setProjectOptions] = useState(false);
  const optionRef = useRef(null);
  useClickOutside(optionRef, () => setProjectOptions(false));
  const useLoc = useLocation();
  return (
    <div
      key={user.id}
      className="bg card-header rounded-lg shadow-md cursor-pointer transition duration-300 ease-in-out hover:scale-105"
    >
      <div className="px-4 py-2">
        <div className="flex justify-between h-14 items-center">
          <h1 className="text-base text-[#DAE7FF] ">{user.name}</h1>
          {roleHandler(adminSuperAdmin) && (
              <div
                className="flex relative"
                ref={optionRef}
                onClick={(e) => {
                  e.stopPropagation();
                  setProjectOptions(!projectOptions);
                }}
              >
                {isCurrentPath(useLoc,"/users") && (
                  <div className=" flex">
                  <PencilSquareIcon className=" w-8 h-8 text-gray-300"  onClick={() => {
                    handleEdit(user);
                  }}/>
                  <EyeIcon className=" w-8 h-8 text-gray-300 ml-4" onClick={()=>viewTable(true)}/>
                  </div>
                )}
              </div>
            )}
        </div>
      </div>
      <div className="p-4 bg card-content rounded-bl-lg rounded-br-lg">
        <div>
          <div className="border-b border-[#1E2B43] mb-4 pb-4 flex items-center">
          <UserIcon className=" w-6 h-6 custom-blue-text"/>
            <p className="text-sm custom-blue-text flex-grow ml-2">
              {user.name}
            </p>
          </div>
          <div className="flex items-center mb-4 pb-4 border-b border-[#1E2B43]">
          <EnvelopeIcon className=" w-6 h-6 custom-blue-text"/>
            <p className="text-sm custom-blue-text flex-grow ml-2">
              {user.officialEmail}
            </p>
          </div>
          <div className="flex items-center">
          <ViewfinderCircleIcon className=" w-6 h-6 custom-blue-text"/>

            <p className="text-sm custom-blue-text flex-grow ml-2">
              {user.role}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectMangerCard;
