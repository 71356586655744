import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IFOCUS_SUPER_ADMIN,role, closed, inActive, projects } from "../../constants/Roles";
import { PencilSquareIcon,UserIcon,EnvelopeIcon,BriefcaseIcon, UserPlusIcon, EyeIcon } from "@heroicons/react/24/outline";
import { useMyContext } from "../../context/ContextApi";


const ClientCard = ({ client, handleEdit,viewClientTable }) => {
  const { dispatch } = useMyContext();
  const [showOptions, setShowOptions] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const location = useLocation();
  const currentURL = location.pathname;

  const handleClientClick = (client) => {
    const clientInfo=JSON.stringify({
      clientName: client?.clientName,
      clientId: client?.clientId,
    })
    localStorage.setItem(
      "navClientNameId",clientInfo);
    dispatch({ type: 'SET_CLIENT_INFO', payload:clientInfo});
    if (currentURL === "/manage-clients") {
      navigate("/manage-projects", { state: { clientId: client?.clientId, clientName: client?.clientName } });
    } else {
      navigate("/projects", {
        state: { clientId: client?.clientId, clientName: client?.clientName },
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleOptionsToggle = () => {
    setShowOptions(!showOptions);
  };

  const handleUpdate = (client) => {
    handleEdit(client);
  };
  const handleViewClientTable=()=>{
    viewClientTable(true)
  };

  return (
    <div
      key={client.id}
      onClick={() => {
        if (String(client.clientStatus).toLowerCase() === "active") {
          handleClientClick(client);
        }
      }}
      className="bg card-header rounded-lg shadow-md cursor-pointer transition duration-300 ease-in-out  hover:shadow-lg  hover:scale-105"
    >
      <div className="px-4 py-2">
        <div className="flex justify-between h-14 items-center">
          <h1 className="text-base text-[#DAE7FF] ">{client.clientName}</h1>
          {String(client.clientStatus).toLowerCase() === "closed" && (
            <div className="text-[#DAE7FF] border-2 p-1 rounded text-xs ">
              {closed}
            </div>
          )}
          {String(client.clientStatus).toLowerCase() === "inactive" && (
            <div className="text-[#DAE7FF] border-2 p-1 rounded text-xs ">
              {inActive}
            </div>
          )}
          {role === IFOCUS_SUPER_ADMIN &&
            String(client.clientStatus).toLowerCase() == "active" && (
              <div
                className=" flex relative"
                ref={dropdownRef}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOptionsToggle();
                }}
              >
                {currentURL === "/manage-clients" && (
                  <PencilSquareIcon className=" w-8 h-6 text-gray-200" onClick={() => {
                    handleUpdate(client)}}/>
                  
                )}
                {currentURL === "/manage-clients" && (
                  <EyeIcon className=" w-8 h-8 text-gray-300 ml-4" onClick={handleViewClientTable}/>
                  
                )}
              </div>
            )}
        </div>
      </div>
      <div className="p-4 bg card-content rounded-bl-lg rounded-br-lg">
        <div>
          <div className="border-b border-[#1E2B43] mb-4 pb-4 flex items-center">
            <UserIcon className=" w-6 h-6 custom-blue-text" />
            <p className="text-sm custom-blue-text flex-grow ml-2">
              {client.pocName}
            </p>
          </div>
          <div className="flex items-center mb-4 pb-4 border-b border-[#1E2B43]">
          <EnvelopeIcon className=" w-6 h-6 custom-blue-text" />
           
            <p className="text-sm custom-blue-text flex-grow ml-2">
              {client.pocEmailAddress}
            </p>
          </div>
          <div className="flex items-center mb-4 pb-4 border-b border-[#1E2B43]">
          <UserPlusIcon className=" w-6 h-6 custom-blue-text" />
           
            <p className="text-sm custom-blue-text flex-grow ml-2">
              {client.accountManager}
            </p>
          </div>
          <div className="flex items-center">
          <BriefcaseIcon className=" w-6 h-6 custom-blue-text" />
            <p className="text-sm custom-blue-text flex-grow ml-2">
            {client.projectCount} {projects} 
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientCard;
