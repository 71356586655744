import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import LocalStorage from "../../StorageUtil/LocalStorage";

const navigation = [];
let {role,name} = LocalStorage.getResponseData();
role = role && role.replaceAll("_", " ");
const userNavigation = [
  { name: "Reset password", href: "/reset-password" },
  { name: "Sign out", href: "/" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const handleSignOut = () => {
  LocalStorage.logout();
};

const openMenuHandler = (data) => {
  if (data === -1 || !name[data + 1]) {
    return "";
  }
  return name[data + 1].toUpperCase();
};

const getFormattedName = (name) => {
  if (!name) return '';

  const nameParts = name.trim().split(' ');
  const firstName = nameParts[0]?.toUpperCase() || '';
  const lastName = nameParts[1]?.toUpperCase() || '';

  return `${firstName} ${lastName}`;
};


export default function Header() {
  function getInitials(name) {
    if (!name) return '';
    const nameParts = name.split(' ').filter(part => part.length > 0);
    const initials = nameParts.map(part => part[0].toUpperCase()).join('');
    return initials;
  }
  return (
    <>
      <div className="min-h-full">
        <Disclosure as="nav" className="bg-gray-900">
          {({ open }) => (
            <>
              <div className="px-20 pt-4">
                <div className="flex h-16 items-center justify-end">
                  <div className="hidden md:flex items-center">
                    <div className="flex flex-row">
                      <BellIcon
                        className="h-9 w-10 text-gray-300 mr-8 mt-3"
                        aria-hidden="true"
                      />
                      <span>
                        <p className="text-[#C8DBFF] text-lg">
                          {String(name).charAt(0).toUpperCase() +
                            String(name).slice(1)}
                        </p>
                        <p className="text-[#C8DBFF] text-sm">{role}</p>
                      </span>
                    </div>

                    <div className="relative ml-6">
                      <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"></div>
                    </div>
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                          <span className="absolute -inset-1.5" />
                          <span className="sr-only">Open user menu</span>
                          <div className="h-10 w-10 rounded-full bg-[#C8DBFF]  flex justify-center items-center">
                            <div className="font-bold text-lg text-[#111827]">
                              {getInitials(name)}
                            </div>
                          </div>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  href={item.href}
                                  onClick={
                                    item.name === "Sign out"
                                      ? handleSignOut
                                      : null
                                  }
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>

                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                  {navigation.length > 0 &&
                    navigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className={classNames(
                          item.current
                            ? "bg-gray-900 text-white"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                          "block rounded-md px-3 py-2 text-base font-medium"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                </div>
                <div className="border-t border-gray-700 pb-3 pt-4">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <div className="h-10 w-10 rounded-full bg-[#C8DBFF]  flex justify-center items-center">
                        <div className="font-bold text-lg text-[#111827]">
                        {getFormattedName(name)}
                        </div>
                      </div>
                    </div>
                    <div className="ml-3 flex flex-col gap-y-2">
                      <p className="text-base font-medium leading-none text-white">
                        {String(name).charAt(0).toUpperCase() +
                          String(name).slice(1)}
                      </p>
                      <p className="text-sm leading-none text-white">{role}</p>
                    </div>
                    <button
                      type="button"
                      className="relative ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-3 space-y-1 px-2">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        onClick={
                          item.name === "Sign out" ? handleSignOut : null
                        }
                        href={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        {/* <main>
          <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
           
          </div>
        </main> */}
      </div>
    </>
  );
}
