import React from "react";
import { toast } from "react-toastify";
import { ArrowUpOnSquareIcon } from "@heroicons/react/24/outline";
import { apiMultiPartPost } from "../../utils/apiHelper";
import {
  uploadPdfFile,
  errorUploadFile,
  fileUploadSuccess,
  uploadReport,
  unExpectedError,
  fileSizeExceed,
} from "../../constants/Roles";
const MAX_FILE_SIZE_MB = 10;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;
const ACCEPTED_FILE_TYPE = 'application/pdf';

const UploadBtn = () => {
  const validateFile = (file) => {
    if (file.type !== ACCEPTED_FILE_TYPE) {
      return uploadPdfFile;
    }
    if (file.size > MAX_FILE_SIZE_BYTES) {
      return fileSizeExceed;
    }
    return null;
  };
  
  const handleFileChange = async (event) => {
    event.preventDefault();
    
    const file = event.target.files[0];
    if (!file) return;
  
    const validationError = validateFile(file);
    if (validationError) {
      toast.error(validationError);
      return;
    }
  
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await apiMultiPartPost('/report/monthly', formData);
      if (response.status === 200) {
        window.location.reload();
        toast.success(fileUploadSuccess);
      } else {
        toast.error(errorUploadFile);
      }
    } catch (error) {
      toast.error(unExpectedError);
    }
  };

  return (
    <div className=" flex">
      <input
        type="file"
        id="file"
        accept=".pdf, .PDF"
        onChange={handleFileChange}
        className="hidden"
      />
      <div className="flex bg-btnColorBlue items-center cursor-pointer space-x-2 text-white w-fit rounded-md py-2.5 px-6 text-base">
        <label htmlFor="file" className="cursor-pointer">
          {uploadReport}
        </label>
        <ArrowUpOnSquareIcon className=" w-6 h-6 custom-blue-text" />
      </div>
    </div>
  );
};

export default UploadBtn;
