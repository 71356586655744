import React from "react";
import ClientCard from "./ClientCard";
import { cardLoading } from "../../utils/dateConverter";

const ClientList = ({ clients, handleEdit, setNavClientData,isLoading ,viewClientTable}) => {
  const sortClients = () =>
  clients.sort((a, b) => {
    const statusOrder = { active: 1, inactive: 2, closed: 3 };
    return (
      statusOrder[String(a.clientStatus).toLowerCase()] -
      statusOrder[String(b.clientStatus).toLowerCase()]
    );
  });
  return (
    <>
      {clients.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-7 mt-4">{
          sortClients().map((client) => (
          
            <ClientCard
              key={client.clientId}
              client={client}
              handleEdit={handleEdit}
              setNavClientData={setNavClientData}
              viewClientTable={viewClientTable}
            />
         ))}</div>
      ) : (
        (cardLoading(isLoading)) 
      )}
    </>
  );
};

export default ClientList;
