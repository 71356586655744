const LocalStorage = {
  getItem: (key) => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  },

  setItem: (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  },

  removeItem: (key) => {
    localStorage.removeItem(key);
  },

  isLoggedIn: () => {
    return localStorage.getItem("isLoggedIn") === "true";
  },

  getResponseData: () => {
    return LocalStorage.getItem("userDetails") ||{};
  },

  getProjectName: () => {
    return LocalStorage.getItem("navProjectName") ||{};
  },

  getClientName: () => {
    return LocalStorage.getItem("navClientNameId")||{};
  },
  getRedirect: () => {
    return LocalStorage.getItem("url")||{};
  },

  login: (responseData) => {
    localStorage.setItem("isLoggedIn", "true");
    LocalStorage.setItem("userDetails", responseData);
  },
  urlInfo:(info)=>{
    LocalStorage.setItem("url",info);
  },

  logout: () => {
    const keys = [
      "isLoggedIn",
      "userDetails",
      "navProjectName",
      "navClientNameId",
      "url",
    ];
    keys.forEach((key) => LocalStorage.removeItem(key));
  },
};

export default LocalStorage;
