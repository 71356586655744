import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  fileUploadSuccess,
  selectWeek,
  submit,
  uploadReport,
  allowedRole,
  removeOtherData,
  invalidExcel,
  errorUploadFile,
  workerError,
  weeklyExcel,
  workerFuntion,
  loadButton,
} from "../../constants/Roles";
import { v4 as uuidv4 } from "uuid";
import {
  weekOptions,
  genricExcelTable,
  excelValidate,
  hasUndefined,
} from "../../utils/dateConverter";
import { apiRequest } from "../../utils/apiHelper";
import LocalStorage from "../StorageUtil/LocalStorage";

const WeeklyUploadBtn = ({ userRole, handleFileUploadSuccess }) => {
  const [showExcelTable, setShowExcelTable] = useState(false);
  const [fileLoad, setFileLoad] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedWeek, setSelectedWeek] = useState("");
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { id } = LocalStorage.getProjectName();
  useEffect(() => {
    setShowExcelTable(excelValidate(errors));
    if (hasUndefined(data)) {
      setShowExcelTable(true);
      toast.error(invalidExcel);
    }
  }, [errors]);

  const fileInputRef = useRef(null);
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileLoad(true);
      setSelectedFile(file);
      setSelectedFileName(file ? file.name : "");

      const reader = new FileReader();
      const worker = new Worker(workerFuntion);
      reader.onload = async (event) => {
        setLoading(true);
        worker.postMessage({
          type: weeklyExcel,
          data: event.target.result 
        });
        worker.onmessage = (e) => {
          const { data, errors } = e.data;
          setData(data);
          setErrors(errors);
          setLoading(false);
          setFileLoad(false);
        }
        worker.onerror = (error) => {
          console.error(workerError, error);
          toast.error(errorUploadFile);
          setLoading(false);
          setFileLoad(false);
        };
      };

      reader.readAsArrayBuffer(file);
    }
  };
  const handleSubmit = async () => {
    if (selectedFile && selectedWeek && !showExcelTable) {
      try {
        const updatedReport = data.map((item) => ({
          ...item,
          week: selectedWeek,
          projectid: id,
        }));

        const response = await apiRequest(
          "POST",
          `/reports/uploadWeeklyReports`,
          updatedReport
        );

        if (response.status === 200) {
          toast.success(fileUploadSuccess);
          setSelectedFile(null);
          setSelectedFileName("");
          handleFileUploadSuccess();
        }
      } catch (error) {
        toast.error(error.data?.response?.message);
      }
    } else {
      showExcelTable ? toast.error(removeOtherData) : toast.error(selectWeek);
    }
  };

  if (allowedRole.includes(userRole)) {
    return null;
  }

  return (
    <>
      <div className=" mt-4">
        <div className="flex">
          <select
            className="ml-4 mr-4 bg-gray-900 border border-gray-700 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight text-[#6B778E] focus:outline-none focus:shadow-outline"
            onChange={(e) => setSelectedWeek(e.target.value)}
            required
          >
            <option value="">{selectWeek}</option>
            {weekOptions?.map((week, index) => (
              <option key={week} value={week}>
                {week}
              </option>
            ))}
          </select>

          <input
            type="file"
            accept=".xlsx, .xls"
            ref={fileInputRef}
            onChange={handleFileChange}
            className="hidden"
          />
          {loading && (
            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-blue-500 border-t-transparent"></div>
          )}
          <button
            onClick={() => fileInputRef.current.click()}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            {selectedFileName ? selectedFileName : uploadReport}
          </button>
          <button
            onClick={handleSubmit}
            className="ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            {fileLoad ? loadButton : submit}
          </button>
        </div>
        {showExcelTable && data.length > 0 && (
          <div className="border mt-4 border-solid overflow-x-auto border-gray-700 rounded-lg p-4 mb-4">
            <table className="min-w-full divide-y divide-gray-700">
              <thead className="bg-gray-800">
                <tr>
                  {data.length > 0 &&
                    Object.keys(data[0]).map((key, index) => (
                      <th
                        className="px-4 h-14 border border-slate-300 text-left text-xs font-medium text-nowrap text-gray-300 uppercase tracking-wider"
                        key={uuidv4()}
                      >
                        {key}
                      </th>
                    ))}
                </tr>
              </thead>
              {genricExcelTable(data, errors)}
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default WeeklyUploadBtn;
