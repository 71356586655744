import React from "react";
import { useNavigate,Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import LocalStorage from "../components/StorageUtil/LocalStorage";
import Header from "../components/Layout/Header/Header";
import Breadcrumb from "../components/Layout/Header/Breadcrumb";
import { apiMultiPartPut } from "../utils/apiHelper";
import { cancel, confirmPass, confirmPassRequired, currentPass, currentPassRequired, currentPassStar, emailRequired, home, invalidEmail, newPass, newPassMust, newPassStar, passMatch, passMust, passMustContain, passNotSame, passReset, resetPass, yourEmail } from "../constants/Roles";


const UpdatePassword = () => {
const {id,officialEmail} = LocalStorage.getResponseData();
  const initialValues = {
    email: officialEmail,
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    email: Yup.string().email(invalidEmail).required(emailRequired),
    currentPassword: Yup.string().required(currentPassRequired),
    newPassword: Yup.string()
      .min(8, passMust)
      .matches(
        /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]+$/,
        passMustContain
      )
      .required(newPassMust),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref(newPass), null], passMatch)
      .notOneOf([Yup.ref(currentPass), null], passNotSame)
      .required(confirmPassRequired),
});

  const breadcrumbItems = [
    { name: home, href: '#' },
    { name: resetPass, current: true },
  ];

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const formData = new FormData();
      formData.append("currentPassword", values.currentPassword);
      formData.append("newPassword", values.newPassword);
      await apiMultiPartPut(`/user/reset/${id}`, formData);
      toast.success(passReset);
      navigate('/login');
      setSubmitting(false);
    } catch (error) {
      toast.error(error.data.response.data);
      setSubmitting(false);
    }
  };

  return (
    <>
      <Header />
      <div className="container mx-auto h-full px-4">
        <Breadcrumb items={breadcrumbItems} />
        <section  style={{ height: "80vh" }}  className="bg-gray-50 dark:bg-gray-900 h-80vh">
          <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-full lg:py-0">
            <div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
              <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
               {resetPass}
              </h2>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form className="mt-4 space-y-4 lg:mt-5 md:space-y-5">
                    <div>
                      <label
                        htmlFor="email"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {yourEmail}
                      </label>
                      <Field
                        type="email"
                        name="email"
                        id="email"
                        disabled
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="currentPassword"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {currentPassStar}
                      </label>
                      <Field
                        type="password"
                        name="currentPassword"
                        id="currentPassword"
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        required
                      />
                      <ErrorMessage
                        name="currentPassword"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="newPassword"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                       {newPassStar}
                      </label>
                      <Field
                        type="password"
                        name="newPassword"
                        id="newPassword"
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        required
                      />
                      <ErrorMessage
                        name="newPassword"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="confirmPassword"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {confirmPass}
                      </label>
                      <Field
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        required
                      />
                      <ErrorMessage
                        name="confirmPassword"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                    <div className="flex flex-col sm:flex-row justify-between">
                    <Link to={'/dashboard'}>
                    <button
                      type="submit"
                      className="w-full sm:w-40 text-black bg-white hover:bg-primary-700 focus:ring-2 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 mb-4 sm:mb-0"
                      disabled={isSubmitting}
                    >
                      {cancel}
                    </button>
                    </Link>
                    <button
                      type="submit"
                      className="w-full sm:w-40 text-white bg-blue-500 hover:bg-primary-700 focus:ring-2 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                      disabled={isSubmitting}
                    >
                      {resetPass}
                    </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default UpdatePassword;
