import React, { useEffect, useState ,useRef} from "react";
import Header from "../Layout/Header/Header";
import Breadcrumb from "../Layout/Header/Breadcrumb";
import UploadBtn from "./UploadBtn";
import PDFViewer from "./PDFViewer";
import { COORDINATOR,role, adminCoordinator, errorFetchData,keyRelease,meWatchReport,roleHandler,viewDetails } from "../../constants/Roles";
import { toast } from "react-toastify";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import MonthlyRelease from "../Reports/MonthlyRelease";
import { useMyContext } from "../../context/ContextApi";
import { clientName,projectName } from "../../utils/dateConverter";
import {  apiGet } from "../../utils/apiHelper";

const MonthlyReport = () => {
  const{state}=useMyContext();
  const breadcrumbs = [
    { name: "Home", href: "/dashboard", current: true },
    { name: "Clients", href: "/clients", current: true },
    {
      name: `${clientName || JSON.parse(state?.navClientNameId)?.clientName} Projects`,
      href: "/projects",
      current: true,
    },
    {
      name: `${projectName || state?.navProjectName} Reports`,
      href: "/report",
      current: true,
    },
    {
      name: `${projectName || state?.navProjectName} Monthly Report`,
      href: "/monthly-report",
      current: false,
    },
  ];
  const [allFiles, setAllFiles] = useState([]);
  const [uploadedFileView, setUploadedFileView] = useState(null);

  const handleViewFile = (fileName) => {
    setUploadedFileView(fileName);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiGet(`/report/get-all`);

        setAllFiles(response.data);
        if (response.data.length > 0) {
          const latestFile = response.data[response.data.length - 1];
          setUploadedFileView(latestFile.reportName);
        }
      } catch (error) {
        toast.error(errorFetchData);
      }
    };

    fetchData();
  }, []);
  const PdfViewer = useRef(null);
  const downloadPdfHandler = () => {
    PdfViewer.current.pdfDownloadFunction();
  };

  

  return (
    <div className="max-h-screen">
      <Header />
      <div className="mx-auto max-h-screen px-4 sm:px-6 lg:px-8 ">
        <div className="flex flex-col sm:flex-row sm:items-center mb-4">
        {role!== COORDINATOR && 
          <Breadcrumb items={breadcrumbs} />
          }
        </div>

        <div className="flex flex-row item-center justify-between mr-10">
          <div className="w-1/2">
            <h3 className="text-3xl font-medium leading-6 text-white">
            {meWatchReport}
            </h3>
          </div>
          <div className=" flex gap-4">
            <div className="flex bg-btnColorBlue items-center cursor-pointer space-x-2 text-white w-fit rounded-md py-2.5 px-6 text-base" onClick={downloadPdfHandler}>
              <p className=" text-white font-bold">PDF</p>
              <ArrowDownTrayIcon
                className=" w-6 h-6 text-white hover:text-blue-500"
              />
            </div>
            <div className="">{roleHandler(adminCoordinator) ? <UploadBtn /> : null}</div>
          </div>
        </div>
        <div className="flex  justify-between mt-14">
          <ul className="overflow-auto scrollbar absolute max-h-80 xl:h-[50rem] 2xl:h-screen lg:max-h-[23.125rem] max-w-full">
            {allFiles.map((report) => {
              const parts = report.reportName.split("_");
              const fileName = parts
                .slice(2)
                .join("_")
                .replace(/_/g, " ")
                .replace(".pdf", "");
              const fileDate = parts
                .slice(4)
                .join("_")
                .replace(/_/g, " ")
                .replace(".pdf", "");

              return (
                <li
                  key={report.id}
                  className="text-field-content rounded-md text-white border border-[#293C5D] w-[500px] p-4 mb-5"
                >
                  <div className="flex items-center justify-between">
                    <div className="flex flex-col">
                      <p className="text-[#C7D5EE] font-semibold text-lg">
                        {fileName}
                      </p>
                      <p className="text-[#6B778E] font-normal  mt-2">
                        {fileDate}
                      </p>
                    </div>
                    <button
                      onClick={() => handleViewFile(report.reportName)}
                      className="bg-[rgb(22,32,50)] text-[#A4B8DD] font-normal border border-[#2C4063] py-2 px-4 rounded-full"
                    >
                      {viewDetails}
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="border-2 relative left-[550px] border-[#162032]"></div>

          <PDFViewer uploadedFileView={uploadedFileView} ref={PdfViewer}  />
        </div>
        <div className="flex flex-row item-center justify-between mt-10">
          <div className="w-1/2">
            <h3 className="text-3xl font-medium leading-6 text-white">
              {keyRelease}
            </h3>
          </div>
          
        </div>
        <div className="py-6"><MonthlyRelease/></div>
      </div>
    </div>
  );
};

export default MonthlyReport;
