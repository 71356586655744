import React, { useState, useEffect } from "react";
import Header from "../Layout/Header/Header";
import WeeklyGraph from "./WeeklyGraph";
import Breadcrumb from "../Layout/Header/Breadcrumb";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';
import { useMyContext } from "../../context/ContextApi";
import { errorFetchData, meWatchReport, weeklyReport } from "../../constants/Roles";
import { getCurrentWeek,clientName,projectName,weekOptions } from "../../utils/dateConverter";
import { apiGet } from "../../utils/apiHelper";
const WeeklyInsights = () => {
  const{state}=useMyContext();
  const breadcrumbs = [
    { name: "Home", href: "/dashboard", current: true },
    { name: "Clients", href: "/clients", current: true },
    {
      name: `${clientName || JSON.parse(state?.navClientNameId)?.clientName}  Projects`,
      href: "/projects",
      current: true,
    },
    {
      name: `${projectName || state?.navProjectName} Reports`,
      href: "/report",
      current: true,
    },

    {
      name: `${projectName || state?.navProjectName} Reports Insights`,
      href: "/insights",
      current: false,
    },
  ];

  const [selectedWeek, setSelectedWeek] = useState(getCurrentWeek());
  const [weeklyData, setWeeklyData] = useState(null);
  const [datas, setData] = useState([""]);


  const fetchDataForWeek = async () => {
    try {
      const response = await apiGet(
        `/reports/get/${selectedWeek}`
      );

      const {
        numberOfTicketsVerified,
        numberOfTCsExecuted,
        totalDefectsReported,
      } = response.data;

      setWeeklyData({
        numberOfTicketsVerified: parseFloat(numberOfTicketsVerified),
        numberOfTCsExecuted:
          numberOfTCsExecuted === "-" ? 0 : parseInt(numberOfTCsExecuted),
        totalDefectsReported: parseFloat(totalDefectsReported),
      });
      setData(response.data);
    } catch (error) {
      toast.error(errorFetchData);
    }
  };

  useEffect(() => {
    if (selectedWeek) {
      fetchDataForWeek();
    }
  }, [selectedWeek]);

  const handleWeekChange = (event) => {
    setSelectedWeek(event.target.value);
  };

  return (
    <div className="bg-gray-900 min-h-screen">
      <Header />
      <div className="bg-gray-900 mt-2 items-center justify-center">
        <div className="mx-auto  px-4 py-16 sm:px-6 sm:py-6  lg:px-8 custom-bg-color">
          <Breadcrumb items={breadcrumbs} />

          <div>
            <div className="flex flex-col sm:flex-row sm:items-center mb-4"></div>

            <div className="flex flex-row items-center justify-between">
              <h3 className="text-2xl font-medium leading-6 text-white">
                {meWatchReport}
              </h3>
              <div className="flex flex-row items-center">
                <div className="border border-solid border-gray-700 rounded-lg p-2">
                  <select
                    value={selectedWeek}
                    onChange={handleWeekChange}
                    className="block appearance-none w-full sm:w-auto bg-gray-900 border-none rounded shadow leading-tight text-[#6B778E] focus:outline-none focus:shadow-outline"
                  >
                    <option value="">All Weeks</option>
                    {weekOptions.map((week, index) => (
                      <option key={uuidv4()} value={week}>
                        {week}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="border border-solid border-gray-700 rounded-lg mt-6 p-4 mb-4 relative">
              <h1 className="text-[#c9ccd2] mt-3 font-bold">{weeklyReport}</h1>
              {weeklyData && <WeeklyGraph data={weeklyData} datas={datas} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeeklyInsights;
