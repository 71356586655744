import {React,useState,useEffect }from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import ContextApi from "./context/ContextApi";
import LocalStorage from "./components/StorageUtil/LocalStorage";

function App() {
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());

  useEffect(() => {
    const logoutTimer = setInterval(() => {
      const currentTime = Date.now();
      const inactiveTime = currentTime - lastActivityTime;
      const inactiveMinutes = inactiveTime / (1000 * 60);

      if (inactiveMinutes >= 59) {
        // Log out the user if any user not perform any task with in one our system automatic logout
        clearSession();
        window.location.href = '/login';
      }
    }, 60000); // Check for inactivity every minute

    return () => clearTimeout(logoutTimer);
  }, [lastActivityTime]);

  const handleUserActivity = () => {
    setLastActivityTime(Date.now());
  };

  const clearSession = () => {
    LocalStorage.logout()
  };
  return (
    <div onMouseMove={handleUserActivity} onKeyDown={handleUserActivity} className="App">
      <Router>
        <ContextApi>
          <AppRoutes />
        </ContextApi>
      </Router>
    </div>
  );
}

export default App;
