import React from "react";
import { Link,useNavigate } from "react-router-dom";
function ReportUploadOption() {
  const Navigate = useNavigate();
  return (
    <>
      <a className="grid place-items-center h-screen ">
        <div className=" bg-white border border-gray-200 rounded-lg shadow md:flex-row dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
        <div className="grid grid-cols-1 md:grid-cols-2  gap-4 p-4">
          <div>
            <Link to={"/weeklytable"}>
              <div className="h-auto max-w-full rounded-lg bg-fuchsia-300 p-12 text-lg font-semibold transition ease-in-out delay-150  hover:skew-y-3 hover:bg-red-500 duration-300">
                Weekly
              </div>
            </Link>
          </div>
          <div>
            <Link to={"/monthly-report"}>
              <div className="h-auto max-w-full whitespace-nowrap rounded-lg bg-teal-400 p-12 text-lg font-semibold transition ease-in-out delay-150  hover:skew-y-3 hover:bg-green-300 duration-300">
                Monthly
              </div>
            </Link>
          </div>
        </div>
        <button
          onClick={() => Navigate(-1)}
          type="danger"
          className="inline-flex justify-center ml-4 mb-2 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-gray-400 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Back
        </button>
        </div>
        
      </a>
    </>
  );
}

export default ReportUploadOption;
