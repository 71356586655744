// Project.js
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../components/Layout/Header/Header";
import Breadcrumb from "../components/Layout/Header/Breadcrumb";
import ProjectList from "../components/Project/ProjectList";
import ProjectModel from "../components/Project/ProjectModel";
import { active, adminSuperAdmin, errorFetchData, errorSave, failedToFetch, roleHandler, successCreate, successUpdate } from "../constants/Roles";
import { BackwardIcon } from "@heroicons/react/16/solid";
import { v4 as uuidv4 } from 'uuid';
import { apiGet, apiPost ,apiPut} from "../utils/apiHelper";
const Project = () => {
  const tableHeadings = [
    "PROJECT NAME",
    "LOCATION",
    "PROJECT POC EMAIL ADDRESS",
    "PROJECT POC NAME",
    "PROJECT TECHNOLOGIES",
    "PROJECT TYPE",
    "REMARKS",
    "START DATE",
    "END DATE",
    "PROJECT STATUS"
  ];
  const[viewTable,setViewTable]=useState(false);
  const location = useLocation();
  const currentURL = location.pathname;
  const [projectModal, setProjectModal] = useState({
    isOpen: false,
    project: null,
  });
  const [client, setClient] = useState(null);
  const [isLoading, setisLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const breadcrumbItems = client
    ? [
        { name: "Home", href: "#" },
        {
          name: "Clients",
          href:
            currentURL === "/manage-projects" ? "/manage-clients" : "/clients",
          current: false,
        },
        { name: `${client.clientName ?? ""} Projects`, current: true },
      ]
    : [];

  useEffect(() => {
    const clientData = {
      clientId: location.state?.clientId,
      clientName: location.state?.clientName,
    };
    setClient(clientData);
  }, [location.state]);

  useEffect(() => {
    if (client) {
      fetchProjects();
    }
  }, [client]);

  const fetchProjects = async () => {
    try {
      const response = await apiGet(client.clientId===undefined?`/projects`:
        `/projects/projects/${client.clientId}`
      );

      if(response.status===200){
        setProjects(response.data);
        setisLoading(false)
      }
      else{
        toast.error(failedToFetch);
        setisLoading(false)
      }
  
    } catch (error) {
      toast.error(errorFetchData);
    }
  };

  const handleModalOpen = (project) => {
    setProjectModal({ isOpen: true, project });
  };

  const handleModalClose = () => {
    setProjectModal({ isOpen: false, project: null });
  };

  const handleSaveProject = async (values) => {
    values.client = { clientId: client.clientId };
    if (!("projectStatus" in values)) {
      values.projectStatus = active;
    }
    try {
      if (projectModal.project) {
        await apiPut(
          `/projects/update/${projectModal.project.id}`,
          values
        );
        toast.success(successUpdate);
      } else {
        await apiPost(`/projects`, values);
        toast.success(successCreate);
      }
      fetchProjects();
      handleModalClose();
    } catch (error) {
      toast.error(errorSave);
    }
  };
  const viewTableHandle=()=>{
    setViewTable(!viewTable)
  }
  return (
    <>
      <Header />
      <div className="container mx-auto px-4 py-8">
        {/* Breadcrumb */}
        <Breadcrumb items={breadcrumbItems} />
        {viewTable ? (
          <div className="bg-gray-900 min-h-screen">
          <div className="h-screen overflow-y-auto bg-gray-900 mt-2 items-center justify-center">
            <div className="mx-auto  px-4 py-16 sm:px-6 sm:py-6  lg:px-8 custom-bg-color">
              <div className="w-full flex justify-between items-center"></div>
              <div className="flex flex-col sm:flex-row items-center justify-between mt-4">
                <h3 className="font-medium leading-6 text-white sm:mt-0">
                  Project Details
                </h3>
                <BackwardIcon className=" w-8 h-8 text-white hover:text-blue-400" onClick={()=>{setViewTable(false)}}/>
              </div>
              <div className="overflow-x-auto mt-4">
                {projects.length !== 0 && (
                  <div className="border border-solid border-gray-700 rounded-lg p-4 mb-4">
                    <table className="min-w-full divide-y divide-gray-700">
                      <thead className="bg-gray-800">
                        <tr>
                          {tableHeadings.map((heading, index) => (
                            <th
                              key={uuidv4()}
                              className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                            >
                              {heading}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="bg-gray-900 divide-y divide-gray-700">
                        {projects
                          .map((css, index) => (
                            <tr key={css.id}>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                                {css.projectName}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                                {css.location}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                                {css.projectPocEmailAddress}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                                {css.projectPocName}
                              </td>
                              <td
                                className={`px-6 py-4 whitespace-nowrap text-sm text-gray-300 hover:text-blue-900 cursor-pointer`}
                              >
                                {css.projectTechnologies}
                              </td>
                              <td
                                className={`px-6 py-4 whitespace-nowrap text-sm text-gray-300 hover:text-blue-900 cursor-pointer`}
                              >
                                {css.projectType}
                              </td>
                              <td
                                className={`px-6 py-4 whitespace-nowrap text-sm text-gray-300 hover:text-blue-900 cursor-pointer`}
                              >
                                {css.remarks}
                              </td>
                              <td
                                className={`px-6 py-4 whitespace-nowrap text-sm text-gray-300 hover:text-blue-900 cursor-pointer`}
                              >
                                {css.startDate}
                              </td>
                              <td
                                className={`px-6 py-4 whitespace-nowrap text-sm text-gray-300 hover:text-blue-900 cursor-pointer`}
                              >
                                {css.endDate}
                              </td>
                              <td
                                className={`px-6 py-4 whitespace-nowrap text-sm text-gray-300 hover:text-blue-900 cursor-pointer`}
                              >
                                {css.projectStatus}
                              </td>
                            </tr>
                            
                          ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        )
        :(
        <div>

        {/* Project Section */}
        <div className="flex justify-between items-center mt-4">
          <h2 className="text-2xl text-[#E6E6E6] font-semibold">
            {client?.clientName} Projects
          </h2>
          {currentURL === "/manage-projects" &&
          roleHandler(adminSuperAdmin) ? (
            <button
              className="custom-btn-color text-white px-3 py-1 rounded-sm w-40 h-11"
              onClick={() => handleModalOpen(null)}
            >
              Add Project +
            </button>
          ) : null}
        </div>

        {/* Cards Section */}
        <ProjectList
          projects={projects}
          handleEdit={handleModalOpen}
          client={client}
          isLoading={isLoading}
          viewTable={viewTableHandle}
        />

        {/* Add/Update Project Modal */}
        {projectModal.isOpen && (
          <ProjectModel
            onClose={handleModalClose}
            initialValues={projectModal.project || {}}
            onSubmit={handleSaveProject}
            projects={projects}
            client={client}
          />
        )}
        </div>
)}
      </div>
    </>
  );
};

export default Project;
