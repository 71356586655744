// ProjectList.js
import React, { useState } from "react";
import UserCard from "./UserCard";
import ProjectMangerCard from "./ProjectMangerCard";
import { PROJECT_MANAGER } from "../../constants/Roles";
const UserList = ({ user, handleEdit,isLoading,viewTable }) => { 
  const [callProjectManager, setCallProjectManager] = useState(false);
  const handleProjectManager=()=>{
    setCallProjectManager(!callProjectManager)
  }
  const resultWithoutProjectManager = [];
  const resultWithProjectManager = [];

user.forEach(item => {
    if (item.role === PROJECT_MANAGER) {
        resultWithProjectManager.push(item);
    } else {
        resultWithoutProjectManager.push(item);
    }
});
  return (
    <>
      {callProjectManager===false? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-7 mt-4">
          {resultWithoutProjectManager.map((usr) => (
            <UserCard
              key={usr.id}
              projectManager={handleProjectManager}
              user={usr}
              handleEdit={handleEdit}
              viewTable={viewTable}
            />
          ))}
        </div>
      ) :(
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-7 mt-4">
          {resultWithProjectManager.map((usr) => (
            <ProjectMangerCard
              key={usr.id}
              user={usr}
              handleEdit={handleEdit}
              viewTable={viewTable}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default UserList;
