import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { weekDataNotFound } from "../../constants/Roles";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const WeeklyGraph = ({ datas }) => {
  if (!datas || datas.length === 0) {
    return (
      <div className="text-gray-400 text-center">
        {weekDataNotFound}
      </div>
    );
  }

  const uniquePlatforms = Array.from(
    new Set(datas.map((item) => item.platform))
  );

  const platformStats = {};

  datas.forEach((item) => {
    const {
      platform,
      numberOfTicketsVerified,
      totalDefectsReported,
      criticalBugs,
    } = item;

    const hasValidData =
      parseFloat(numberOfTicketsVerified) > 0 ||
      parseFloat(totalDefectsReported) > 0 ||
      parseFloat(criticalBugs) > 0;

    if (hasValidData) {
      if (!platformStats[platform]) {
        platformStats[platform] = {
          numberOfTicketsVerified: parseFloat(numberOfTicketsVerified) || 0,
          totalDefectsReported: parseFloat(totalDefectsReported) || 0,
          criticalBugs: parseFloat(criticalBugs) || 0,
        };
      } else {
        platformStats[platform].numberOfTicketsVerified +=
          parseFloat(numberOfTicketsVerified) || 0;
        platformStats[platform].totalDefectsReported +=
          parseFloat(totalDefectsReported) || 0;
        platformStats[platform].criticalBugs += parseFloat(criticalBugs) || 0;
      }
    }
  });

  const filteredPlatforms = uniquePlatforms.filter(
    (platform) =>
      platformStats[platform]?.numberOfTicketsVerified > 0 ||
      platformStats[platform]?.totalDefectsReported > 0 ||
      platformStats[platform]?.criticalBugs > 0
  );

  const graphData = [
    {
      label: 'No. of tickets',
      data: filteredPlatforms.map((platform) => {
        const platformData = platformStats[platform];
        return parseFloat(platformData.numberOfTicketsVerified) || null;
      }),
      backgroundColor: '#1C64F2',
    },
    {
      label: 'No. of defects reported',
      data: filteredPlatforms.map((platform) => {
        const platformData = platformStats[platform];
        return parseFloat(platformData.totalDefectsReported) || null;
      }),
      backgroundColor: '#F09067',
    },
    {
      label: 'No. of critical bugs',
      data: filteredPlatforms.map((platform) => {
        const platformData = platformStats[platform];
        return parseFloat(platformData.criticalBugs) || null;
      }),
      backgroundColor: '#DE5757',
    },
  ];

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Platform Statistics',
      },
      legend: {
        position: 'top',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Platform',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Count',
        },
      },
    },
  };

  return (
    <div>
      <Bar
        data={{
          labels: filteredPlatforms,
          datasets: graphData,
        }}
        options={options}
        style={{ width: '100%', height: '100%' }}
        className="w-full h-full"
      />
    </div>
  );
};

export default WeeklyGraph;
