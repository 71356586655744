import React from "react";
import { v4 as uuidv4 } from 'uuid';
const generativeTable = () =>
  [...Array(8)].map((e, i) => (
    <tr key={uuidv4()}>
      <td className="px-6 py-6 whitespace-nowrap text-sm text-gray-300"></td>
    </tr>
  ));
const TableLoader = () => {
  return (
    <div className="border border-solid border-gray-700 rounded-lg p-4 mb-4 animate-pulse">
      <table className="min-w-full divide-y divide-gray-700">
        <thead className="bg-gray-800">
          <tr>
            <th className="px-6 py-6 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"></th>
          </tr>
        </thead>
        <tbody className="bg-gray-900 divide-y divide-gray-700">
       {generativeTable()}
        </tbody>
      </table>
    </div>
  );
};

export default TableLoader;
