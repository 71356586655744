import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import {errorFetchData} from "../../constants/Roles"
import 'react-pdf/dist/cjs/Page/AnnotationLayer.css';
import 'react-pdf/dist/cjs/Page/TextLayer.css';

import { toast } from 'react-toastify';
import { apiBlobGet } from '../../utils/apiHelper';

const NewsLtrPDFViewer = ({ uploadedFileView }) => {
  const [numPages, setNumPages] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    const fetchData = async () => {
      if (uploadedFileView) {
        try {
          const url = `/news-letter/download/${uploadedFileView}`;
          const response = await apiBlobGet(url)
          const pdfBlobUrl = URL.createObjectURL(response.data);
          setPdfUrl(pdfBlobUrl);
        } catch (error) {
          toast.error(errorFetchData);
        }
      }
    };
    fetchData();
  }, [uploadedFileView]);

  return (
    <div className="overflow-auto scrollbar max-h-80 xl:h-[50rem] 2xl:h-screen lg:max-h-[23.125rem] max-w-full">
      <div className="text-white">
        {pdfUrl && (
          <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={index + 1}
                pageNumber={index + 1}
                className={index === numPages - 1 ? '' : 'mb-4'}
              />
            ))}
          </Document>
        )}
      </div>
    </div>
  );
};

export default NewsLtrPDFViewer;
