import React from 'react';
import { toast } from 'react-toastify';
import { ArrowUpCircleIcon } from '@heroicons/react/24/outline';
import { apiMultiPartPost } from '../../utils/apiHelper';
import { errorSave, errorUploadFile, fileUploadSuccess, uploadLetter } from '../../constants/Roles';

const NewsLtrUploadBtn = ({ refreshPage }) => {
  const handleFileChange = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await apiMultiPartPost(
        `/news-letter/upload`,
        formData
      );

      if (response.status === 200) {
        toast.success(fileUploadSuccess);
        refreshPage();
      } else {
        toast.error(errorUploadFile);
      }
    } catch (error) {
      toast.error(errorSave);
    }
  };

  return (
    <div>
      <input
        type="file"
        id="file"
        onChange={handleFileChange}
        className="hidden"
      />
      <div className="flex bg-btnColorBlue items-center cursor-pointer space-x-2 text-white w-fit rounded-md py-2.5 px-6 text-base">
        <label htmlFor="file" className="cursor-pointer">
          {uploadLetter}
        </label>
        <ArrowUpCircleIcon className=" w-8 h-8 custom-blue-text"/>
      </div>
    </div>
  );
};

export default NewsLtrUploadBtn;
