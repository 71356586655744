import React, { useReducer, useContext,useMemo } from 'react'
import { MyContext } from './MyContext'
const initialState = {
  navProjectName: null,
  navClientNameId:null,
};
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_PROJECT_NAME':
      return { ...state, navProjectName: action.payload };
    case 'SET_CLIENT_INFO':
      return { ...state, navClientNameId: action.payload };
    default:
      return state;
  }
};



const ContextApi = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  return (
    <div>
      <MyContext.Provider value={value}>{children}</MyContext.Provider>
    </div>
  );
};
export const useMyContext = () => {
  return useContext(MyContext);
};

export default ContextApi