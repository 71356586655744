import React from 'react';
import {downloadNewsletter, errorFetchData} from "../../constants/Roles"
import { toast } from 'react-toastify';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { apiBlobGet } from '../../utils/apiHelper';

const NewsLtrDownloadBtn = ({ uploadedFileDownload }) => {
  const handleDownloadFile = async () => {
    if (uploadedFileDownload) {
      try {
        const url = `/news-letter/download/${uploadedFileDownload}`;
        const response = apiBlobGet(url)
        const pdfBlobUrl = URL.createObjectURL(response.data);
        const alink = document.createElement('a');
        alink.href = pdfBlobUrl;

        const fileName = uploadedFileDownload
          .split('_')
          .slice(2)
          .join('_')
          .replace('.pdf', '');

        alink.download = `${fileName}.pdf`;
        alink.click();
      } catch (error) {
        toast.error(errorFetchData);
      }
    }
  };
  return (
    <div>
      <button
        onClick={handleDownloadFile}
        className="flex bg-btnColorBlue items-center cursor-pointer space-x-2 text-white w-fit rounded-md py-2.5 px-6 text-base"
      >
        <label htmlFor="file" className="cursor-pointer">
          {downloadNewsletter}
        </label>
        <ArrowDownTrayIcon className=" w-8 h-8 custom-blue-text"/>
      </button>
    </div>
  );
};

export default NewsLtrDownloadBtn;
