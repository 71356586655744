import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { toast } from "react-toastify";
import { getCurrentWeek } from "../../utils/dateConverter";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { count, errorFetchData, noCriticalBug, noDefects, noTickets, platform, platformStatistics, top, weekDataNotFound } from "../../constants/Roles";
import { apiGet } from "../../utils/apiHelper";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const WeeklyGraphForCard = () => {
  const [datas, setDatas] = useState([]);
  const currentWeek = getCurrentWeek();

  useEffect(() => {
    fetchDataForWeek(currentWeek);
  }, [currentWeek]);

  const fetchDataForWeek = async (week) => {
    try {
      const response = await apiGet(`/reports/get/${week}`);
      setDatas(response.data.slice(0, 5));
    } catch (error) {
      toast.error(errorFetchData);
    }
  };

  if (!datas || datas.length === 0) {
    return (
      <div className="text-gray-400 mt-10 text-center">
        {weekDataNotFound}
      </div>
    );
  }

  const uniquePlatforms = Array.from(new Set(datas.map((item) => item.platform)));

  const graphCardData = [
    {
      label: noTickets,
      data: uniquePlatforms.map((platform) => {
        const platformData = datas.find((data) => data.platform === platform);
        return parseFloat(platformData?.numberOfTicketsVerified) || null;
      }),
      backgroundColor: '#1C64F2',
    },
    {
      label: noDefects,
      data: uniquePlatforms.map((platform) => {
        const platformData = datas.find((data) => data.platform === platform);
        return parseFloat(platformData?.totalDefectsReported) || null;
      }),
      backgroundColor: '#F09067',
    },
    {
      label: noCriticalBug,
      data: uniquePlatforms.map((platform) => {
        const platformData = datas.find((data) => data.platform === platform);
        return parseFloat(platformData?.criticalBugs) || null;
      }),
      backgroundColor: '#DE5757',
    },
  ];

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: platformStatistics,
      },
      legend: {
        position: top,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: platform,
        },
      },
      y: {
        title: {
          display: true,
          text: count,
        },
      },
    },
  };

  return (
    <Bar
      data={{
        labels: uniquePlatforms,
        datasets: graphCardData,
      }}
      options={options}
      className="w-full h-full sm:w-96 sm:h-76"
    />
  );
};

export default WeeklyGraphForCard;
