import React from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

const ForgotPassword = ({
  forgotPasswordMail,
  handleSubmitForgotPassword,
  handleBackToLogin,
}) => {
  return (
    <Formik
      initialValues={{ email: forgotPasswordMail }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email("Invalid email address")
          .required("Email is required")
          .matches(/@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Invalid Email address"),
      })}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmitForgotPassword(values);
        setSubmitting(false);
      }}
    >
      <Form className="space-y-6">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-xl font-bold leading-9 tracking-tight text-gray-400">
            Forgot Password
          </h2>
        </div>
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium leading-6 text-gray-400"
          >
            Email address
          </label>
          <Field
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            className="border border-gray-500 p-2 pl-10 w-full rounded-md bg-gray-800 text-white"
          />
          <ErrorMessage
            name="email"
            component="div"
            className="text-yellow-400 text-sm"
          />
        </div>
        <div className="flex justify-between">
          <button
            type="button"
            className="flex-1 mr-2 justify-center rounded-md bg-gray-300 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
            onClick={handleBackToLogin}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="flex-1 ml-2 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Submit
          </button>
        </div>
      </Form>
    </Formik>
  );
};

export default ForgotPassword;
