import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import LocalStorage from "../StorageUtil/LocalStorage";
import ForgotPassword from "./ForgotPassword";
import {
  forgotPassword,
  loginValdiation,
  invalidEmail,
  login,
  loginAccount,
  usernameRequired,
} from "../../constants/Roles";
import { apiPost } from "../../utils/apiHelper";

const LoginForm = ({ onLogin }) => {
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [forgotPasswordMail, setforgotPasswordMail] = useState("");

  useEffect(() => {
    if (showForgotPassword) {
      setforgotPasswordMail("");
    }
  }, [showForgotPassword]);
  const loginUser = async (values) => {
    const { username, password } = values;
    try {
      const response = await apiPost(`/login`, {
        login: username,
        password: password,
      });

      if (response.status === 200) {
        const responseData = response.data;
        LocalStorage.login(responseData);
        const {currentUrl}=LocalStorage.getRedirect()
        if (currentUrl && currentUrl !== "/") {
          window.location.href = currentUrl
        } else {
          if (response.data.role === "COORDINATOR") {
            window.location.href = "/report-menu";
          } else {
            window.location.href = "/dashboard";
          }
        }
      }
    } catch (error) {
      toast.error(loginValdiation.wrongCred);
    }
  };

  const handleSubmitForgotPassword = async (values) => {
    const { email } = values;
    try {
      const response = await apiPost(`/user/forgot-password/${email}`, {
        email: email,
      });

      if (response.status === 200) {
        toast.success(loginValdiation.sucessPass);
        setTimeout(() => {
          window.location.href = "/login";
        }, 2000);
      } else {
        toast.error(loginValdiation.passResetFailed);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        autoClose: 4000, // Set auto close time to 4000 milliseconds (4 seconds)
      });
      toast.error(loginValdiation.passResetError);
    }
  };

  const handleForgetPasswordLink = (userMail) => {
    setShowForgotPassword(true);
    setforgotPasswordMail(userMail);
  };

  const handleBackToLogin = () => {
    setShowForgotPassword(false);
  };

  return (
    <div className="bg-gray-900  overflow-hidden ">
      <div className="flex flex-col   md:flex-row ">
        <div className=" md:w-1/2 max-h-screen bg-gray-800 text-white  ">
          <div className="flex-grow  flex items-center justify-center max-h-screen relative bottom-15 p-1 md:p-16  overflow-hidden">
            <div className="flex flex-col p-1 md:p-5 pr-0">
              <h1 className="text-2xl 2xl:text-4xl font-light">
                Unlock insights and share <br /> your feedback with ease.
              </h1>
              <img
                src="../assets/img/images/login image.png"
                alt="Login Page"
                className="max-h-full"
              />
            </div>
          </div>
        </div>
        {/* left side */}
        <div className="md:w-5/12 flex flex-col max-h-screen">
          <div className="-ml-1 md:ml-20 mt-2 max-w-full flex justify-between">
            <img
              className="h-8 w-20 m-5"
              src="assets/img/images/logo.png"
              alt="ifocusLogo"
            />
          </div>
          <div className="max-w-400 mr-10 md:mr-20 ml-3 md:ml-7   w-full p-2 md:p-16 relative -left-3 md:left-4">
            {showForgotPassword ? (
              <ForgotPassword
                forgotPasswordMail={forgotPasswordMail}
                handleSubmitForgotPassword={handleSubmitForgotPassword}
                handleBackToLogin={handleBackToLogin}
              />
            ) : (
              <Formik
                initialValues={{ username: "", password: "" }}
                validationSchema={Yup.object({
                  username: Yup.string()
                    .email(invalidEmail)
                    .required(usernameRequired),
                  password: Yup.string()
                    .min(8, loginValdiation.passMustEight)
                    .required(loginValdiation.passRequired),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  loginUser(values);
                  setSubmitting(false);
                }}
              >
                {({ values }) => (
                  <Form>
                    <h2 className="text-2xl font-bold  mt-16 mr-3 mb-3 text-gray-400">
                      {loginAccount}
                    </h2>
                    <div className="mb-6 relative h-12">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <img
                          src="assets/img/username.png"
                          alt="Logo"
                          className="h-5 w-5"
                        />
                      </div>
                      <Field
                        type="text"
                        name="username"
                        placeholder="name@ifocussystec.com"
                        className="border border-gray-500 p-2 pl-10 w-full rounded-md bg-gray-800 text-white"
                      />
                      <ErrorMessage
                        name="username"
                        component="div"
                        className="text-yellow-400 text-sm"
                      />
                    </div>
                    <div className="mb-4 relative h-12">
                      <Field
                        type="password"
                        name="password"
                        placeholder="Password"
                        className="border border-gray-500 p-2 pl-10 w-full rounded-md bg-gray-800 text-white"
                      />
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <img
                          src="assets/img/pass.png"
                          alt="Logo"
                          className="h-5 w-5"
                        />
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-yellow-400 text-sm"
                      />
                    </div>
                    <div className="mb-4 flex justify-end mr-2">
                      <div
                        className="text-sm text-gray-300 cursor-pointer"
                        onClick={() =>
                          handleForgetPasswordLink(values.username)
                        }
                      >
                        {forgotPassword}
                      </div>
                    </div>

                    <div>
                      <button
                        className="bg-blue-500 text-white py-2 px-4 w-full"
                        type="submit"
                      >
                        {login}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
