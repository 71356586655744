// ProjectList.js
import React from "react";
import QuestionCard from "./QuestionCard";
import { cardLoading } from "../../utils/dateConverter";
const QuestionList = ({ question, handleEdit,isLoading,viewTable }) => {
  

  return (
    <>
      {question.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-7 mt-4">
          {question.map((ques) => (
            <QuestionCard
              key={ques.id}
              question={ques}
              handleEdit={handleEdit}
              viewTable={viewTable}
            />
          ))}
        </div>
      ) : cardLoading(isLoading)}
    </>
  );
};

export default QuestionList;
