// ProjectList.js
import React from "react";
import ProjectCard from "./ProjectCard";
import { cardLoading } from "../../utils/dateConverter";

const ProjectList = ({ projects, handleEdit, client,isLoading,viewTable }) => {
  const sortProjects = () =>
    projects.sort((a, b) => {
      const statusOrder = { active: 1, inactive: 2, closed: 3 };
      return (
        statusOrder[String(a.projectStatus).toLowerCase()] -
        statusOrder[String(b.projectStatus).toLowerCase()]
      );
    });

  return (
    <>
      {projects.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-7 mt-4">
          {sortProjects().map((project) => (
            <ProjectCard
              key={project.id}
              project={project}
              handleEdit={handleEdit}
              client={client}
              viewTable={viewTable}
            />
          ))}
        </div>
      ) : cardLoading(isLoading)}
    </>
  );
};

export default ProjectList;
