import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MyContext } from "../../../context/MyContext";
import { v4 as uuidv4 } from "uuid";
import { ChevronRightIcon, HomeIcon } from "@heroicons/react/24/outline";
const Breadcrumb = ({ items }) => {
  const { navClientData } = useContext(MyContext);
  const navigate = useNavigate();
  const handleClick = (values) => {
    if (values === "/projects") {
      navigate(values, {
        state: {
          clientId: navClientData?.clientId,
          clientName: navClientData?.clientName,
        },
      });
    } else {
      navigate(values);
    }
  };
  const breadcrumbHandler = (index, item) => {
    if (index === 0) {
      return (
        <Link
          to="/dashboard"
          className={`inline-flex items-center text-sm font-medium ${
            item.current ? "text-gray-500" : "text-gray-700  "
          } md:ms-2  cursor-pointer hover:text-blue-600`}
        >
          <HomeIcon className="w-3 h-3 me-2.5"/>
          Home
        </Link>
      );
    } else if (item.href) {
      return (
        <div
          className={`text-sm font-medium ${
            item.current ? "text-gray-500" : "text-gray-700  "
          } md:ms-2 cursor-pointer hover:text-blue-600 `}
          onClick={() => handleClick(item.href)}
        >
          {item.name}
        </div>
      );
    } else {
      return (
        <span
          className={`text-sm font-medium ${
            item.current ? "text-gray-500" : "text-gray-700 "
          } md:ms-2 dark:text-gray-400 cursor-pointer hover:text-blue-600`}
        >
          {item.name}
        </span>
      );
    }
  };
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
        {items.map((item, index) => (
          <li key={uuidv4()} aria-current={item.current ? "page" : null}>
            <div className="flex items-center">
              {index !== 0 && (
                <ChevronRightIcon className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"/>
              )}
              {breadcrumbHandler(index,item)}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
