import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getCurrentWeek } from "../../utils/dateConverter";
import { apiGet } from "../../utils/apiHelper";
import { dataNotFound, errorFetchData } from "../../constants/Roles";
import { v4 as uuidv4 } from "uuid";
const WeeklyTableForCard = () => {
  const [tableData, setTableData] = useState([]);
  const currentWeek = getCurrentWeek();

  useEffect(() => {
    fetchDataForWeek(currentWeek);
  }, [currentWeek]);

  const fetchDataForWeek = async (week) => {
    try {
      const response = await apiGet(`/reports/get/${week}`);
      setTableData(response.data.slice(0, 5));
    } catch (error) {
      toast.error(errorFetchData);
    }
  };
  const tableHeadings = ["Platform", "Name", "Version", "Build Type"];

  return (
    <div className="mt-4 scroll-m-1 overflow-hidden flex-grow">
      {tableData.length > 0 ? (
        <table className="border-collapse border border-white w-full text-[#C8DBFF] table-auto rounded-lg">
          <thead>
            <tr>
              {tableHeadings.map((heading, index) => (
                <th
                  key={uuidv4()}
                  className="bg-gray-900 border border-[#2C4063] p-1 text-sm"
                >
                  {heading}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((item, index) => (
              <tr key={uuidv4()}>
                <td className=" border border-[#2C4063] p-2 text-sm">
                  {item.platform}
                </td>
                <td className="border border-[#2C4063] p-2 text-sm">
                  {item.project}
                </td>

                <td className="border border-[#2C4063] p-2 text-sm">
                  {item.versionEnvironment}
                </td>
                <td className="border border-[#2C4063] p-2 text-sm">
                  {item.buildType}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>{dataNotFound}</p>
      )}
    </div>
  );
};

export default WeeklyTableForCard;
