import React from "react";
import { v4 as uuidv4 } from 'uuid';
const generativeGridCards = () => (
  [...Array(8)].map((e, i) => (
    <div key={uuidv4()} className="shadow-md animate-pulse">
      <div className="h-14 rounded-t-lg card-header  "></div>
      <div className="h-12 card-content border-b border-[#1E2B43]"></div>
      <div className="h-12 card-content border-b border-[#1E2B43]"></div>
      <div className="h-12 rounded-b-lg card-content"></div>
    </div>
  ))
)
const GridCardLoader = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-7 mt-4">
      {generativeGridCards()}
    </div>
  );
};

export default GridCardLoader;
