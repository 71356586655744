
export const surveyRequest = {
    message: {
      questionField: "Question*",
      select:"Select",
      initiateSurvey:"Initiate Survey",
      enterDetail:"Enter Details",
      customerRepresentativeName:"Customer Representative Name*",
      customerRepresentativeEmail:"Customer Representative Email*",
      clientName:"Client Name*",
      feedbackPeriod:"Feedback Period",
      projectName:"Project Name*",
      cancel:"Cancel",
      initiate:"Initiate"
    },
    validation: {
      customer: {
        required: "Customer representative name is required",
      },
      email: {
        invalid: "Invalid email address",
        required: "Customer representative email is required",
      },
      project:{
        required: "Project name is required",
      },
      feedback:{
        period:"Feedback period cannot be in the future",
        startDate:"Feedback period start date is required",
        periodFrom:"feedbackPeriodFrom",
        endDate:"Feedback period end date must be after start date",
        endDateRequired:"Feedback period end date is required",
      }

    },
  };
  export const clientModel = {
    message: {
      questionField: "Question*",
      select:"Select",
      clientDetails:"Client Details",
      pocDetailForClient:"Point of contact (POC) details for client",
      clientName:"Client name*",
      location:"Location*",
      pocName:"POC name*",
      pocContactNumber:"POC contact number*",
      pocEmailAddress:"POC Email address*",
      accountManagerName:"iFocus account manager name*",
      clientStatusName:"Client Status*",
      remarks:"Remarks",
      cancel:"Cancel",
      save:"Save"
    },
    clientStatus:{
      select:"Select Client Status",
      active:"ACTIVE",
      inActive:"INACTIVE",
      closedOption:"CLOSED"
    },
    validation: {
      client: {
        required: "Client name is required",
        nameExits:"Client name already exists"
      },
      location:{
        required:"Location is required",
        mustContain:"Location must contain only alphabets"
      },
      pocContact: {
        required: "POC contact number is required",
        invalid:"Invalid POC contact number",

      },
      pocEmail:{
        required:"POC Email address is required",
        invalid:"Invalid POC Email address"
        
      },
      accountManager:{
        required:"iFocus account manager name is required",
        must:"Account manager must contain only alphabets"
      },
      projectType:{
        required:"Project type is required",
        remarkLength:"The maximum length of the Remarks field is 100 characters",
        active:"ACTIVE"
      },
      mobileNumber:{
        must:"Mobile number must start with 7 or 8 in Singapore",
        start:"Mobile number cannot start with 9 in Singapore"
      }
    },
  };

export const feedbackForm={
  message:{
    failedToFetch:'Failed to fetch data.',
    dataSaveSuccess:'Data saved successfully.',
    emptyValue:"Please fill current value first",
    saved:'saved',
    faliedToSave:'Failed to save data.',
    errorSaved:'An error occurred while saving data.',
    dataUpdateSuccess:'Data updated successfully.',
    updated:'updated',
    failedToUpdate:'Failed to update data.',
    allFieldMust:'All fields must be filled.',
    startDate:"Start Date",
    endDate:"End Date",


    lastValue:"Last Field Can't Deleted",
    csiUpdate:"CSI Action Updates successfully",
    actionNotPerform:"Action Not perform yet",
    errorFetchData:"Error fetching data:",
    requiredField:"This field is required",
    thankYouFeedback:"Thank you for giving feedback!",
    encounteredSubmitForm: "We encountered a problem while trying to submit your form. Please double-check your entries for any errors and try submitting again.",
    css: "Customer Satisfaction Survey",
    kindlyRate: "*Kindly rate our performance for all 7 attributes",
    customerRep: "Customer representative",
    projectName: "Project name",
    from: "From",
    to:"To",
    actionToImprove: "Actions to improve CSI/Sustain 100% CSI",
    cancel:"Cancel",
    submitSurvey: "Submit Survey",
    update:"Update",
    save:"Save",
  },
  validation: {
    cssAction: {
      required: "Comment is required",
      startDateRequired:'Start Date is required',
      startDate:'startDate',
      endDateMust:'End Date must be after Start Date',
      endDateRequired:'End Date is required',
      atLeast:'At least one action is required',


    },
}
}

export const testing={
  message:{
    dateFormet:"Date Formet testing",
    date:"02/07/2024",
    converDate:"2024-7-2",
    converWeek:"current week testing",
    tableLoaderTest:"tableLoaderHandler",
    noDataFoundRender:'renders "No data found" when isLoading is false',
    noDataFoundDot:"No data found.",
    noDataFound:"No data found",
    cardLoaderTest:"cardLoaderHandler",
    newRender:"newLetter render",
    friday:"Friday",

  },
}