
import React from "react";
import { Form, DatePicker, ConfigProvider } from "antd";
import dayjs from "dayjs";
import { CalendarOutlined } from '@ant-design/icons';

const FormItem = Form.Item;


const dateFormat = "YYYY-MM-DD";

// Here i am adding antd error message through DateInput

const DateInput = ({ field,fieldName,disableDate,defaultDate, form: { touched, errors,setFieldValue }, ...props }) => {
  const errorMsg = touched[field.name] && errors[field.name];
  const  validateStatus=()=>{
    if (errorMsg) {
      return "error";
      } else if (touched[field.name] && !errors[field.name]) {
        return "success";
        } else {
          return undefined;
          }
  }


    function onChange(date, dateString) {
        setFieldValue(fieldName,String(dateString).split("-").reverse().join("-"))
      }
   
  return (
    <div>
      <FormItem
        label={props.label}
        help={errorMsg}
        validateStatus={()=>validateStatus()}
        hasFeedback
        {...props.formitemlayout}
      >
        <ConfigProvider
          theme={{
            token: {
              // Seed Token
              colorPrimary: "white",
              colorPrimaryHover: "#1D2A42",
              borderRadius: 6,
              lineWidth: 0.1,

              // Alias Token
              colorBgContainer: "#141D2E",
              colorBorder: "#1D2A42",
              colorTextPlaceholder: "white",
              colorTextLightSolid: "blue",
            },
          }}
        >
            <DatePicker
              onChange={onChange}
              defaultPickerValue={dayjs(new Date().toISOString().split('T')[0], dateFormat)}
              defaultValue={defaultDate ? dayjs((defaultDate),"YYYY-MM-DD") : ""}
              disabledDate={disableDate}
              
              format={"DD-MM-YYYY"}
              style={{
                width: "100%",
                color: "white",
                height: 42,
              }}
              suffixIcon={
                <CalendarOutlined
                  style={{
                    color: '#1890ff',
                    fontSize: '18px',
                  }}
                />
              }
              size="large"
            />
        </ConfigProvider>
      </FormItem>
    </div>
  );
};
export default DateInput;
