import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Layout/Header/Header";
import { adminSuperAdmin, roleHandler } from "../../constants/Roles";
import { ArrowTrendingUpIcon, BanknotesIcon, ClipboardIcon, NewspaperIcon, TvIcon } from "@heroicons/react/24/outline";

const Dashboard = () => {
  const navigate = useNavigate();
  const handleReportClick = () => {
    navigate("/clients");
  };

  const newLatter=[{
    image:"assets/img/images/Image.png",
    heading:"Enterprise Design",
    paragraph:" Get the latest news and updates delivered straight to your inbox."
  },
  {
    image:"https://picsum.photos/id/1/400/300",
    heading:"Best Gold Price",
    paragraph:" Get the latest news and updates delivered straight to your inbox."
  },
  {
    image:"assets/img/images/Image.png",
    heading:"Best React NAtive App",
    paragraph:" Get the latest news and updates delivered straight to your inbox."
  },
  {
    image:"https://picsum.photos/id/1/400/300",
    heading:"Weather Information",
    paragraph:" Get the latest news and updates delivered straight to your inbox."
  }
]

  return (
    <div className="bg-gray-900 min-h-screen">
      <Header />
      <div className="px-20 py-6">
        <div className="space-y-8">
          <div className="grid lg:grid-cols-8 grid-cols-1 gap-8 ">
            <div className=" lg:col-span-3 col-span-1 text-field-content border border-[#22314B] rounded-2xl p-6 pr-0">
              <div className="grid grid-cols-3 h-96 space-x-5">
                <div className="col-span-1">
                  <div className="flex space-x-3">
                    <TvIcon className=" w-8 h-8 custom-blue-text"/> 
                    <span className="text-[#C8DBFF] text-2xl">Reports</span>
                  </div>
                  <p className=" text-[#6B778E] mt-5 mb-2.5">
                    Recent report forms
                  </p>

                  <button
                    onClick={handleReportClick}
                    className=" text-[#A4B8DD] whitespace-nowrap border mt-56 border-[#2C4063] px-5 py-2 rounded-full "
                  >
                    View details
                  </button>
                </div>
                <div className="flex justify-center items-end pt-10 translate-y-4 col-span-2">
                  <img
                    src="assets/img/images/Body & Footer.png"
                    alt="Report Image"
                    className="h-56 w-full"
                  />
                </div>
              </div>
            </div>

            <div className=" lg:col-span-5 col-span-1 text-field-content  border border-[#22314B]  rounded-2xl p-6">
              <div className="grid grid-cols-12 space-x-3">
                <div className="col-span-5">
                  <div className="flex space-x-3 ml-8 mt-5">
                    
                  <ClipboardIcon className=" w-8 h-8 custom-blue-text"/> 
                    <span className="text-[#C8DBFF] text-2xl  lg:whitespace-nowrap">
                      Customer Satisfaction Survey
                    </span>
                  </div>
                  <p className=" text-[#6B778E] mt-5 ml-8">
                    Recent feedbacks <br /> received
                  </p>

                  <Link to={"/css"}>
                    <button className=" text-[#A4B8DD] border mt-52  border-[#2C4063] px-5 ml-5 py-2 rounded-full ">
                      View details
                    </button>
                  </Link>
                </div>
                <div className="col-span-6">
                  <img
                    className="p-2 mt-16 h-60"
                    src="assets/img/images/Survey.png"
                    alt="ifocusLogo"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="grid lg:grid-cols-5 grid-cols-1 lg:gap-8">
            <div className="col-span-3 text-field-content border border-[#22314B] rounded-2xl p-6">
              <div className="grid grid-cols-2 h-80 gap-7">
                <div className="col-span-1">
                  <div className="flex space-x-3">
                  <NewspaperIcon className=" w-8 h-8 custom-blue-text"/>

                    <span className="text-[#C8DBFF] text-2xl ">Newsletter</span>
                  </div>
                  <p className=" text-[#6B778E] mt-5 mb-3">
                    Latest updates, news and information about Ifocus to keep up
                    to date with happenings on the organization
                  </p>
                  <Link to={"/newsletter"}>
                    <button className=" text-[#A4B8DD] border border-[#2C4063] h-11 mt-40	 px-5 py-2 rounded-full ">
                      View details
                    </button>
                  </Link>
                </div>
                <div>
                  <p className=" text-gray-500">Latest News</p>
          {newLatter.map((item)=>(
                  <div className="max-w-sm rounded overflow-hidden shadow-lg flex justify-between mt-2 gap-2" key={`item-${item}`}>
                    <img
                      className="w-16 h-16 rounded-xl"
                      src={item.image}
                      alt="Newsletter Image"
                    />
                    <div>
                      <div className="font-bold text-base text-white">
                        {item.heading}
                      </div>
                      <p className="text-gray-500 text-xs">
                       {item.paragraph}
                      </p>
                    </div>
                  </div>
                ))}
                </div>
              </div>
            </div>
            <div className=" lg:col-span-2 text-field-content border border-[#22314B] rounded-2xl min-[320px]:max-lg:mt-8">
              <div className="grid grid-cols-2">
                <div className="col-span-1">
                  <div className="flex space-x-3 mt-5 ml-8">
                  <ArrowTrendingUpIcon className=" w-8 h-8 custom-blue-text"/>

                    <span className="text-[#C8DBFF] text-2xl">
                      News & Trends
                    </span>
                  </div>
                  <p className=" text-[#6B778E] mt-5 mb-1 ml-8">
                    Recent trending topics <br /> based on interests
                  </p>
                </div>
                <div className="flex-col col-span-1">
                  <h1 className="uppercase text-sm mb-4 text-[#7786A0] mt-5">
                    trending
                  </h1>
                  <a
                    href={
                      "https://www.financialexpress.com/business/brandwagon-generative-ai-5-trends-that-will-impact-the-future-of-ott-and-video-streaming-platforms-3192168/"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="flex-col">
                      <h3 className="text-white ">Financial express-OTT</h3>
                    </div>
                  </a>
                  <a
                    href={
                      "https://www.technologyreview.com/2024/01/04/1086046/whats-next-for-ai-in-2024/"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <hr className="border border-[#202F4A] mt-3 mb-3" />
                    <div className="flex-col">
                      <h3 className="text-white ">Technology review-AI</h3>
                    </div>
                  </a>
                  <hr className="border border-[#202F4A] mt-3 mb-3" />
                </div>
              </div>
            </div>
          </div>
          {roleHandler(adminSuperAdmin) && (
            <div className="col-span-12 text-field-content border border-[#22314B] rounded-2xl p-6">
              <div className="grid grid-cols-2 gap-7">
                <div className="col-span-1">
                  <div className="flex space-x-3">
                  <BanknotesIcon className=" w-8 h-8 custom-blue-text"/>

                    <span className="text-[#C8DBFF] text-2xl ">
                      Manage clients, projects, users and questions
                    </span>
                  </div>
                  <p className=" text-[#6B778E] mt-5 mb-3">
                    This section allows you to efficiently handle clients
                    ,projects ,users and questions  by providing comprehensive functionalities for creation and updation.
                  </p>
                </div>
                <div className="flex justify-end items-center">
                  <Link to={"/menu"}>
                    <button className=" text-[#A4B8DD] border border-[#2C4063] h-11	 px-5 py-2 rounded-full ">
                      View details
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
