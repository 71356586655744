import React, { useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { adminSuperAdmin, roleHandler } from "../../constants/Roles";
import {  CalendarDaysIcon, EyeIcon, PencilSquareIcon, TableCellsIcon } from "@heroicons/react/24/outline";
import { isCurrentPath,useClickOutside } from "../../utils/dateConverter";

const QuestionCard = ({ question, handleEdit,viewTable }) => {
  const [showOptions, setShowOptions] = useState(false);
  const dropdownRef = useRef(null);
  useClickOutside(dropdownRef, () => setShowOptions(false));
  const location = useLocation();
  return (
    <div
      key={question.id}
      className="bg card-header rounded-lg shadow-md cursor-pointer transition duration-300 ease-in-out hover:scale-105"
    >
      <div className="px-4 py-2">
        <div className="flex justify-between h-14 items-center">
          <h1 className="text-base text-[#DAE7FF] ">{question.name}</h1>
          {roleHandler(adminSuperAdmin) && (
              <div
                className="flex relative"
                ref={dropdownRef}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowOptions(!showOptions);
                }}
              >
                {isCurrentPath(location,"/question") && (
                  <div className=" flex">
                  <PencilSquareIcon className=" w-8 h-8 text-gray-300 hover:text-green-400"  onClick={() => {
                    handleEdit(question);
                  }}/>
                  <EyeIcon className=" w-8 h-8 text-gray-300 ml-4 hover:text-green-400" onClick={()=>viewTable(true)}/>
                  </div>
                )}
              </div>
            )}
        </div>
      </div>
      <div className="p-4 bg card-content rounded-bl-lg rounded-br-lg">
        <div>
          <div className="border-b border-[#1E2B43] mb-4 pb-4 flex items-center">
          <TableCellsIcon className=" w-6 h-6 custom-blue-text"/>
            <p className="text-sm custom-blue-text truncate flex-grow ml-2">
              {question.questionName}
            </p>
          </div>
          <div className="flex items-center mb-4 pb-4 border-b border-[#1E2B43]">
          <CalendarDaysIcon className=" w-6 h-6 custom-blue-text"/>
            <p className="text-sm custom-blue-text flex-grow ml-2">
              {question.createdAt.substr(0,19)}
            </p>
          </div>
          <div className="flex items-center">
          <CalendarDaysIcon className=" w-6 h-6 custom-blue-text"/>

            <p className="text-sm custom-blue-text flex-grow ml-2">
              {question.updatedAt.substr(0,19)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionCard;
