// ClientModel.js
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { clientModel } from "../../constants/Validation";

const ClientModel = ({ onClose, initialValues, clients, onSubmit }) => {
  const DEFAULT_MAX_LENGTH = 10;
  const [isSaving, setIsSaving] = useState(false);
  const mobileNumberSeperation =()=>{
   if(initialValues.pocContactNumber.startsWith("+1")){
      initialValues.pocCountryCode = "+1"
      initialValues.pocContactNumber = initialValues.pocContactNumber.slice(2)
   }
   else if(initialValues.pocContactNumber.startsWith("+91")){
    initialValues.pocCountryCode = "+91"
    initialValues.pocContactNumber = initialValues.pocContactNumber.slice(3)
   }
   else if(initialValues.pocContactNumber.startsWith("+65")){
    initialValues.pocCountryCode = "+65"
    initialValues.pocContactNumber = initialValues.pocContactNumber.slice(3)
   }
  }
  initialValues?.pocContactNumber.length > 0 && mobileNumberSeperation()

  const phoneValidations = {
    "+91": {
      regex: /^[6-9]\d{9}$/,
      maxLength: DEFAULT_MAX_LENGTH,
      errorMessage: null,
    },
    "+1": {
      regex: /^\d{10}$/,
      maxLength: DEFAULT_MAX_LENGTH,
      errorMessage: null,
    },
    "+65": {
      regex: /^[89]\d{7}$/,
      maxLength: 8,
      errorMessage: (value) => {
        if (/^9\d{7}$/.test(value)) {
          return clientModel.validation.mobileNumber.start;
        } else if (/^[89]\d{7}$/.test(value)) {
          return clientModel.validation.mobileNumber.must;
        }
        return null; // No error if it doesn't match the specific cases
      },
    },
    default: {
      regex: /^\d+$/,
      maxLength: DEFAULT_MAX_LENGTH,
      errorMessage: clientModel.validation.pocContact.invalid,
    },
  };

  const validationSchema = Yup.lazy((values) =>
    Yup.object().shape({
      clientName: Yup.string()
        .required(clientModel.validation.client.required)
        .test("is-unique", clientModel.validation.client.nameExits, (value) => {
          // Convert the entered client name to lowercase for comparison
          if (initialValues.clientName.length === 0) {
            const enteredClientName = value.toLowerCase().trim();
            // Check if the entered client name already exists in the clients array
            return !clients.some(
              (client) => client.clientName.toLowerCase() === enteredClientName
            );
          }
          return true;
        }),
      location: Yup.string()
        .required(clientModel.validation.location.required)
        .matches(/^[a-zA-Z\s]+$/, clientModel.validation.location.mustContain),
  
      pocContactNumber: Yup.string()
        .required(clientModel.validation.pocContact.required)
        .test("valid-number", clientModel.validation.pocContact.invalid, (value, { parent }) => {
          const countryCode = parent.pocCountryCode;
          const validation = phoneValidations[countryCode] || phoneValidations.default;
  
          const errorMessage = typeof validation.errorMessage === 'function'
            ? validation.errorMessage(value)
            : validation.errorMessage;
  
          const isValidLength = value.length <= validation.maxLength;
          const isValid = validation.regex.test(value) && isValidLength;
  
          return isValid ? true : errorMessage;
        }),
  
      pocEmailAddress: Yup.string()
        .email(clientModel.validation.pocEmail.invalid)
        .required(clientModel.validation.pocEmail.required)
        .matches(/@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, clientModel.validation.pocEmail.invalid),
  
      accountManager: Yup.string()
        .required(clientModel.validation.accountManager.required)
        .matches(/^[a-zA-Z\s]+$/, clientModel.validation.accountManager.must),
  
      clientStatus: Yup.string().required(clientModel.validation.projectType.required),
  
      remarks: values.clientStatus === clientModel.validation.projectType.active
        ? Yup.string()
            .matches(/^.{1,100}$/, clientModel.validation.projectType.remarkLength)
            .nullable()
        : Yup.string()
            .required(clientModel.validation.projectType.required)
            .matches(/^.{1,100}$/, clientModel.validation.projectType.remarkLength),
    })
  );
  

  const handleSave = async (values, actions) => {
    setIsSaving(true);
    try {
      // Concatenate country code and POC contact number
      const combinedContactNumber = `${values.pocCountryCode}${values.pocContactNumber}`;
      // Omit pocCountryCode from form data object
      const { pocCountryCode, ...formDataWithoutCountryCode } = values;
      // Send combined contact number to backend or perform any other action
      await onSubmit(
        {
          ...formDataWithoutCountryCode,
          pocContactNumber: combinedContactNumber,
        },
        actions
      );
    } finally {
      // Re-enable the save button after submission
      setIsSaving(false);
    }
  };

  return (
    <div className="text-[#D2D2D2] fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-auto">
      <div className="modal-content w-full md:max-w-[80%] lg:max-w-[50%] rounded-lg p-8 transform transition-all duration-300 ease-in-out opacity-100 animate-slide-down">
        <h2 className="text-2xl font-semibold mb-4">{clientModel.message.clientDetails}</h2>

        <p className="custom-blue-text mb-4">
          {clientModel.message.pocDetailForClient}
        </p>
        <Formik
          initialValues={{ ...initialValues, pocCountryCode: initialValues?.pocContactNumber.length > 0 ? initialValues?.pocCountryCode : "+91" }}
          validationSchema={validationSchema}
          onSubmit={handleSave}
        >
          {({ validateForm }) => (
            <Form className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label
                  htmlFor="clientName"
                  className="block custom-blue-text mb-1"
                >
                  {clientModel.message.clientName}
                </label>
                <Field
                  type="text"
                  id="clientName"
                  name="clientName"
                  className="w-full border border-[#1D2A42]  rounded-md px-3 py-2 bg-transparent"
                />
                <ErrorMessage
                  name="clientName"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div>
                <label
                  htmlFor="location"
                  className="block custom-blue-text  mb-1"
                >
                  {clientModel.message.location}
                </label>
                <Field
                  type="text"
                  id="location"
                  name="location"
                  className="w-full border border-[#1D2A42]  rounded-md px-3 py-2 bg-transparent"
                />
                <ErrorMessage
                  name="location"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div>
                <label
                  htmlFor="pocName"
                  className="block custom-blue-text  mb-1"
                >
                  {clientModel.message.pocName}
                </label>
                <Field
                  type="text"
                  id="pocName"
                  name="pocName"
                  className="w-full border border-[#1D2A42]  rounded-md px-3 py-2 bg-transparent"
                />
                <ErrorMessage
                  name="pocName"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div>
                <label
                  htmlFor="pocContactNumber"
                  className="block custom-blue-text  mb-1"
                >
                  {clientModel.message.pocContactNumber}
                </label>
                <div className="flex gap-4">
                  <Field
                    as="select"
                    id="pocCountryCode"
                    name="pocCountryCode"
                    className=" border border-[#1D2A42] rounded-md px-3 py-2 bg-transparent"
                  >
                    <option className="dropdown-content" value="+91">
                      +91
                    </option>
                    <option className="dropdown-content" value="+65">
                      +65
                    </option>
                    <option className="dropdown-content" value="+1">
                      +1
                    </option>
                  </Field>
                  <Field
                    type="tel"
                    id="pocContactNumber"
                    name="pocContactNumber"
                    inputMode="numeric"
                    className="w-full border border-[#1D2A42]  rounded-md px-3 py-2 bg-transparent number-hide-arrows"
                    maxLength={10}
                  />
                </div>

                <ErrorMessage
                  name="pocContactNumber"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div>
                <label
                  htmlFor="pocEmailAddress"
                  className="block custom-blue-text  mb-1"
                >
                  {clientModel.message.pocEmailAddress}
                </label>
                <Field
                  type="email"
                  id="pocEmailAddress"
                  name="pocEmailAddress"
                  className="w-full border border-[#1D2A42] rounded-md px-3 py-2 text-field-content"
                />
                <ErrorMessage
                  name="pocEmailAddress"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div>
                <label
                  htmlFor="accountManager"
                  className="block custom-blue-text  mb-1"
                >
                  {clientModel.message.accountManagerName}
                </label>
                <Field
                  type="text"
                  id="accountManager"
                  name="accountManager"
                  className="w-full border border-[#1D2A42]  rounded-md px-3 py-2 bg-transparent"
                />
                <ErrorMessage
                  name="accountManager"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div>
                <label
                  htmlFor="clientStatus"
                  className="block custom-blue-text mb-1"
                >
                  {clientModel.message.clientStatusName}
                </label>
                <Field
                  as="select"
                  id="clientStatus"
                  name="clientStatus"
                  className="w-full border border-[#1D2A42] rounded-md px-3 py-2 bg-transparent"
                >
                  <option className="dropdown-content" value="">
                  {clientModel.clientStatus.select}
                  </option>
                  <option className="dropdown-content" value={clientModel.clientStatus.active}>
                  {clientModel.clientStatus.active}
                  </option>
                  <option className="dropdown-content" value={clientModel.clientStatus.inActive}>
                  {clientModel.clientStatus.inActive}
                  </option>
                  <option className="dropdown-content" value={clientModel.clientStatus.closedOption}>
                    {clientModel.clientStatus.closedOption}
                  </option>
                </Field>
                <ErrorMessage
                  name="clientStatus"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div className="md:col-span-2">
                <label
                  htmlFor="remarks"
                  className="block custom-blue-text  mb-1"
                >
                  {clientModel.message.remarks}
                </label>
                <Field
                  as="textarea"
                  id="remarks"
                  name="remarks"
                  className="w-full border border-[#1D2A42] rounded-md px-3 py-2 bg-transparent"
                />
                <ErrorMessage
                  name="remarks"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
              <div className="md:col-span-2 flex gap-2 justify-end">
                <button
                  type="button"
                  className="text-white px-3 py-1 rounded-sm w-40 h-11"
                  onClick={onClose}
                >
                  {clientModel.message.cancel}
                </button>
                <button
                  type="submit"
                  className="custom-btn-color text-white px-3 py-1 rounded-sm w-40 h-11"
                  disabled={isSaving}
                >
                  {clientModel.message.save}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ClientModel;
