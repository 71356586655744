import React from "react";
import { useNavigate } from "react-router-dom";
import { CursorArrowRaysIcon } from "@heroicons/react/24/outline";


const CSSCard = ({ cssForm }) => {

  const startDate = cssForm.fromDate;
  const endDate = cssForm.toDate;
  const navigate = useNavigate()
  function formatDuration(startDate, endDate) {
    const startMonth = new Date(startDate).toLocaleDateString(undefined, { month: 'long' });
    const endMonth = new Date(endDate).toLocaleDateString(undefined, { month: 'long' });
    const startYear = new Date(startDate).getFullYear();
    const endYear = new Date(endDate).getFullYear();
  
      return `${startMonth} ${startYear} - ${endMonth} ${endYear}`;
  }
  const handleFormNavigation = ()=> {
    navigate(`/${cssForm.id}/feedback-form`)
  }
  const duration = formatDuration(startDate, endDate);
  return (
    <>
    {/* Object.keys(cssForm.surveyQuestions).length !== 0 && */}
    { (
        <div onClick={handleFormNavigation} className="border border-[#2F4161] rounded-lg p-5">
        <div className="flex justify-between mb-6">
        <h2 className="leading-5 text-lg text-[#C7D5EE]">
            {cssForm.clientName}
          </h2>
          
          
          <div className=" p-2 rounded-lg bg-blue-900">
            <CursorArrowRaysIcon className=" w-5 h-5 text-gray-300"/>
           
          </div>
          
        </div>
        <h2 className="leading-5 text-lg text-[#C7D5EE]">
            {cssForm.projectName}
          </h2>
        <div className="mb-2">
        <h2 className="leading-5 text-lg mt-3 text-[#C7D5EE]">{cssForm.customerRepresentativeName}</h2>

        </div>
        <div className="flex justify-between">
          <p className="leading-5 font-medium text-[#6B778E]">
            Duration <br />
            <span className="text-[#406AE4]">{duration}</span>
          </p>
          <p className="leading-5 font-medium text-[#6B778E]">
            CSI <br />
            <span className="text-[#406AE4]">{parseFloat(cssForm.customerSatisfactionIndex).toFixed(2)}</span>
          </p>
        </div>
      </div>
    )}
    </>
  );
};

export default CSSCard;
