import React from "react";
import { useNavigate } from "react-router-dom";

const SinglePageError = () => {
  const navigate = useNavigate();
  const handleLogin = () => {
    navigate("/dashboard");
  };
  return (
    <div className="text-white flex flex-col justify-center items-center w-full h-screen pb-32">
      <div className="text-6xl font-light text-[#C8DBFF] ">404</div>
      <div className="text-2xl font-extralight text-[#6B778E]">Page not found</div>
      <button
        className="custom-btn-color text-white px-4 py-2 rounded-lg mt-4 hover:opacity-90 ring-1"
        onClick={handleLogin}
      >
        Go Home
      </button>
    </div>
  );
};

export default SinglePageError;

