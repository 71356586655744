// Users.js
import React, { useState, useEffect } from "react";
import { useLocation,useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../components/Layout/Header/Header"
import {addQuestion, adminSuperAdmin, errorSave, failedToFetch, questionAnswer, questionDetails, roleHandler, successCreate, successUpdate } from "../constants/Roles";
import { BackwardIcon } from "@heroicons/react/16/solid";
import QuestionModel from "../components/Questions/QuestionModel";
import QuestionList from "../components/Questions/QuestionList";
import { v4 as uuidv4 } from 'uuid';
import { apiGet, apiPost, apiPut } from "../utils/apiHelper";
const Question = () => {
    const navigate = useNavigate();
  const tableHeadings = [
    "QUESTION",
    "CREATED AT",
    "UPDATED AT"
  ];
  const[viewTable,setViewTable]=useState(false);
  const location = useLocation();
  const currentURL = location.pathname;
  const [question, setQuestion] = useState([]);
  const [questionModal, setQuestionModal] = useState({
    isOpen: false,
    question: null,
  });
  const [isLoading, setisLoading] = useState(true);
  
 

  

  useEffect(() => {
      fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      const response = await apiGet(
        `/api/question-attributes`
      );
      if(response.status===200){
        setQuestion(response.data);
        setisLoading(false)
      }
      else{
        toast.error(failedToFetch);
        setisLoading(false)
      }
  
    } catch (error) {
      toast.error(failedToFetch);
    }
  };

  const handleModalOpen = (question) => {
    setQuestionModal({ isOpen: true, question });
  };

  const handleModalClose = () => {
    setQuestionModal({ isOpen: false, question: null });
  };

  const handleSaveQuestion = async (values) => {
    try {
      if (questionModal.user) {
        await apiPut(
          `/user/update/${questionModal.question.id}`,
          values
        );
        toast.success(successUpdate);
      } else {
        await apiPost(`/register`, values);
        toast.success(successCreate);
      }
      fetchUser();
      handleModalClose();
    } catch (error) {
      toast.error(errorSave);
    }
  };
  const viewTableHandle=()=>{
    setViewTable(!viewTable)
  }
  return (
    <>
      <Header />
      <div className="container mx-auto px-4 py-8">
        {viewTable && (
          <div className="bg-gray-900 min-h-screen">
          <div className="h-screen overflow-y-auto bg-gray-900 mt-2 items-center justify-center">
            <div className="mx-auto  px-4 py-16 sm:px-6 sm:py-6  lg:px-8 custom-bg-color">
              <div className="w-full flex justify-between items-center"></div>
              <div className="flex flex-col sm:flex-row items-center justify-between mt-4">
                <h3 className="font-medium leading-6 text-white sm:mt-0">
                  {questionDetails}
                </h3>
                <BackwardIcon className=" w-8 h-8 text-white hover:text-blue-400" onClick={()=>{setViewTable(false)}}/>
              </div>
              <div className="overflow-x-auto mt-4">
                {question.length !== 0 && (
                  <div className="border border-solid border-gray-700 rounded-lg p-4 mb-4">
                    <table className="min-w-full divide-y divide-gray-700">
                      <thead className="bg-gray-800">
                        <tr>
                          {tableHeadings.map((heading, index) => (
                            <th
                              key={uuidv4()}
                              className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                            >
                              {heading}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="bg-gray-900 divide-y divide-gray-700">
                        {question
                          .map((ques, index) => (
                            <tr key={ques.id}>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                                {ques.questionName}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                                {ques.createdAt.substr(0,19)}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                                {ques.updatedAt.substr(0,19)}
                              </td>
                            </tr>
                            
                          ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        )
        }
        <div>
            {!viewTable && <div>

        {/* users Section */}
        <div className="flex justify-between items-center mt-4">
            <div className=" flex">
        <BackwardIcon className=" w-8 h-8 text-white hover:text-blue-400" onClick={() => navigate(-1)}/>
          <h2 className="text-2xl ml-10 text-[#E6E6E6] font-semibold">
             {questionAnswer}
          </h2>
          </div>
          {currentURL === "/question" &&
          roleHandler(adminSuperAdmin) ? (
            <button
              className="custom-btn-color text-white px-3 py-1 rounded-sm w-40 h-11"
              onClick={() => handleModalOpen(null)}
            >
              {addQuestion}
            </button>
          ) : null}
        </div>

        {/* Cards Section */}
        <QuestionList
          question={question}
          handleEdit={handleModalOpen}
          isLoading={isLoading}
          viewTable={viewTableHandle}
        />

        {/* Add/Update user Modal */}
        {questionModal.isOpen && (
          <QuestionModel
            onClose={handleModalClose}
            initialValues={questionModal.question || {}}
            onSubmit={handleSaveQuestion}
            question={question}

          />
        )}
        </div>
    }
        </div>
      </div>
    </>
  );
};

export default Question;
