// QuestionModel.js
import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { apiPost, apiPut } from "../../utils/apiHelper";
import {successUpdate,successCreate, questionRequired, questionNameExits, isUnique} from "../../constants/Roles"


const QuestionModel = ({ onClose, initialValues, question, onSubmit}) => {

 
 
  const validationSchema = Yup.lazy((values) =>
    Yup.object().shape({
      name: Yup.string()
        .required(questionRequired)
        .test(isUnique, questionNameExits, function (value) {
          if (Object.keys(initialValues).length === 0) {
            // Convert the entered Project name to lowercase for comparison
            const enteredName = value.toLowerCase().trim();
            // Check if the entered Project name already exists in the Projects array
            return !question.some(
              (ques) =>
                ques.questionName.toLowerCase() === enteredName
            );
          }
          return true;
        }),
    })
  );
  const handleSave =(values)=>{
    handleSaveProject(values)
  }

 



  const handleSaveProject = async (values) => {
    onClose()
    try {
      if (JSON.stringify(initialValues) !=="{}") {
        await apiPut(
          `/api/question-attributes/${values.id}`,
          values
        );
        toast.success(successUpdate);
      } else {
        await apiPost(`/api/question-attributes`, values);
        toast.success(successCreate);
      }
    } catch (error) {
      toast.error("Error saving Question");
    }
  };


 
  return (
    <div className="text-[#D2D2D2] fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-auto">
      <div className="modal-content w-full md:max-w-[80%] lg:max-w-[50%] rounded-lg p-8 transform transition-all duration-300 ease-in-out opacity-100 animate-slide-down">
        
        <h2 className="text-2xl font-semibold">Question Details</h2>
        
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, validateForm }) => (
            <Form className="grid grid-cols-1 md:grid-cols-1 gap-4">
              <div>
                <label
                  htmlFor="questionName"
                  className="block custom-blue-text mb-1"
                >
                  Question Name*
                </label>
                <Field
                  type="textarea"
                  id="questionName"
                  name="questionName"
                  placeholder="Enter Question Here...."
                  className="w-full border border-[#1D2A42]  rounded-md px-3 py-4 bg-transparent"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
  
              <div className="md:col-span-2 flex gap-2 justify-end">
                <button
                  type="button"
                  className="text-white px-3 py-1 rounded-sm w-40 h-11"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={()=>handleSave(values)}
                  className="custom-btn-color text-white px-3 py-1 rounded-sm w-40 h-11"
                  

                >
                  Save
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default QuestionModel;